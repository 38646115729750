// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import React, { useRef, useEffect } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { loadStripe } from '@stripe/stripe-js';

import {
	getVehicleDeliveryEstimate,
	getVehiclePickUpEstimate,
	getDeliveryEstFunc,
	generateMonthlyPaymentEstimateObject,
	getBuyerPortalBaseUrl,
	calcEkhoProcessingFee,
	getPaymentConfigOptions,
	dateFormatData,
	encryptData,
	vehicleNeedsVin,
} from '@ekhodealer/ekho-common/utils';
import {
	TEMP_TAG_FEE,
	TAR_PROCESS_ENUM,
	PICK_UP_LOCATION_TYPE_ENUM,
	ORDER_CREATION_TYPES,
	ADDITIONAL_FIELDS_MAP,
	FinancingActionItemOptions,
	TAR_AGENT_ENUM,
	EKHO_SERVICES_PROVIDER_ID,
} from '@ekhodealer/ekho-common';
import { Button, Icon } from '@ekhodealer/ekho-common/components';
import _ from 'lodash';
// eslint-disable-next-line import/no-cycle
import {
	createAbandonedCart,
	createLog,
	createPlacedOrderObj,
	fetchRelevantFinancingPrograms,
	handlePaymentIntentConfirmed,
	updateAbandonedCartServerCall,
	updateFinancingActionItem,
} from '../serverCalls';
import { storage, createOrGetAccountsForBuyer } from '../firebase';
import {
	DELIVERY_CHOICES,
	DISCOUNT_TYPES,
	PAYMENT_CHOICES,
	PAYMENT_CONFIG_KEYS,
	DISCOUNT_CATEGORIES,
	DISCOUNT_APPLICABILTY_KEYWORDS,
	PREQUAL_STATUSES,
	DEFAULT_CHECKOUT_CONFIG,
	DELIVERY_DATE_FORMATS,
	CHECKOUT_SECTIONS,
	MODULES,
	PAYMENT_CHOICE_TO_SELECTION_MAP,
} from '../common/data/constants';
import { PRE_QUAL_MODAL_PAGE_NAMES } from '../components/modals/preQualComponents/PreQualConsts';
import {
	track_buyer_applied_discount,
	track_discount_failed,
	track_faq_link_clicked,
	track_privacy_link_clicked,
	track_terms_link_clicked,
	track_checkout_prequal_modal_opened,
	track_how_it_works_clicked,
	trackingEnabled,
} from './analyticsFuncs';
import AccordionInnerContent from '../components/checkoutInfoComponents/util/AccordionInnerContent';

export function postRedirectMessage(url) {
	// Sending message to the parent window
	window.parent.postMessage(
		{
			type: 'redirect',
			url,
		},
		'*',
	);
}

export function isInIframe() {
	return window !== window.top;
}

// A function passed to the Ekho Dealer Footer component that will be called when the user clicks on the Chat option
export const chatOnClick = () => {
	if (window.Intercom && window.Intercom('booted')) {
		// Check if Intercom is initialized
		if (window.Intercom('isMessengerOpen')) {
			// If it's open, close it
			window.Intercom('hide');
		} else {
			// If it's closed, open it
			window.Intercom('show');
		}
	} else if (window.Intercom) {
		// If Intercom isn't initialized, initialize it and show the chat
		window.Intercom('boot', {
			api_base: 'https://api-iam.intercom.io',
			app_id: 'f1a94cfb',
			alignment: 'left',
			horizontal_padding: 50,
			vertical_padding: 50,
			hide_default_launcher: true,
			ekho_app: 'checkout',
			oem_key: process.env.REACT_APP_OEM_KEY,
		});
		if (window.Intercom('isMessengerOpen')) {
			// If it's open, close it
			window.Intercom('hide');
		} else {
			// If it's closed, open it
			window.Intercom('show');
		}
	}
};

export function isPurchasePreorder(checkoutCart) {
	return (
		checkoutCart?.orderCreationType === ORDER_CREATION_TYPES.ANTE_PREORDER ||
		checkoutCart?.orderCreationType === ORDER_CREATION_TYPES.ABANDONED_ANTE_PREORDER
	);
}

export function isCartCanon(vehicles) {
	return vehicles.some((vehicle) => vehicle.variantConfigId);
}

export function isFinancing(paymentSelection) {
	return paymentSelection === PAYMENT_CHOICES.LOAN_DOWNPAYMENT;
}

export function isFullPayment(paymentSelection) {
	return paymentSelection === PAYMENT_CHOICES.CHECKOUT_FULL_PAYMENT;
}

export function isKlarnaPayment(paymentSelection) {
	return paymentSelection === PAYMENT_CHOICES.KLARNA_CHECKOUT_FULL_PAYMENT;
}

export const faqOnClick = (oemKey) => {
	track_faq_link_clicked(oemKey);
	window.open('https://ekhodealer.com/ekho-direct-faqs', '_blank');
};

export const privacyOnClick = (oemKey) => {
	track_privacy_link_clicked(oemKey);
	window.open('https://www.ekhodealer.com/ekho-direct-privacy', '_blank');
};

export const termsOnClick = (oemKey) => {
	track_terms_link_clicked(oemKey);
	window.open('https://www.ekhodealer.com/ekho-direct-terms', '_blank');
};

export const howItWorksOnClick = (oemKey, toggleAboutEkhoModal, isPreorderSession) => {
	toggleAboutEkhoModal(true);
	track_how_it_works_clicked(oemKey, isPreorderSession);
};

export function isDiscountLive(discount) {
	const { startsAt, endsAt } = discount;
	const currentTime = Date.now();
	// Check if the current time is between startsAt and endsAt
	return currentTime >= startsAt && currentTime <= endsAt;
}

export function checkIfActiveDiscountCampaigns(oem) {
	const { discountCampaigns } = oem;
	if (!discountCampaigns) return false;
	return Object.values(discountCampaigns).some((campaign) => isDiscountLive(campaign));
}

export function calculatePreDiscountTotalPriceOfVehicle(vehicle, uniqueAccessories) {
	let preDiscountTotalPrice = vehicle.basePrice;
	if (vehicle.accessories) {
		vehicle.accessories.forEach((accessory) => {
			preDiscountTotalPrice += accessory.price;
		});
	}
	if (vehicle.boltOnAccessories) {
		vehicle.boltOnAccessories.forEach((boltOnAccessoryId) => {
			const boltOnAccessory = uniqueAccessories.get(boltOnAccessoryId);
			if (boltOnAccessory) {
				preDiscountTotalPrice += boltOnAccessory.totalPrice;
			}
		});
	}
	return preDiscountTotalPrice;
}

export const debounceSimple = (fn, ms) => {
	let timer;
	return () => {
		clearTimeout(timer);
		timer = setTimeout((...args) => {
			timer = null;
			fn.apply(this, args);
		}, ms);
	};
};

export const calcMaxAccessoryPurchaseWidth = (numElements, accessoryPurchDim, stacked) => {
	if (!numElements) {
		return 0;
	}
	return stacked
		? numElements * accessoryPurchDim.width
		: numElements * (accessoryPurchDim.width + 13); // 13 is the margin!
};

export const organizeCarouselImgs = (
	sortedVehicles,
	sortedAccessories,
	accessoryCounts,
	mainImgUrl = null,
) => {
	if (sortedVehicles.length > 0) {
		const carouselSlides = sortedVehicles.map((vehicle, index) => {
			if (mainImgUrl && index === 0) {
				return {
					src: mainImgUrl,
					altText: vehicle.name,
					captionHeader: '',
					captionText: '',
					key: index,
				};
			}
			return {
				src: vehicle.imgUrl,
				altText: vehicle.name,
				captionHeader: '',
				captionText: '',
				key: index,
			};
		});
		return { carouselSlides };
	}
	if (sortedAccessories.size > 0) {
		const [carouselSlides, carouselInfos] = Array.from(sortedAccessories.values()).reduce(
			([slides, infos], accessory) => {
				const options = accessory.specs.map((spec) => spec.option).join(', ');
				slides.push({
					src: accessory.imgUrl,
					altText: `${accessory.name}${options ? ` - ${options}` : ''}`,
					captionHeader: '',
					captionText: '',
					id: accessory.id,
					key: slides.length,
				});
				infos.push({
					name: `${accessory.name}${options ? ` - ${options}` : ''}`,
					quantity: accessoryCounts.get(accessory.id),
					id: accessory.id,
				});
				return [slides, infos];
			},
			[[], []],
		);
		return { carouselSlides, carouselInfos };
	}
	throw new Error(`Must have at least one item in your cart!`);
};

export function usePrevious(value) {
	const reference = useRef();
	useEffect(() => {
		reference.current = value; // assign the value of ref to the argument
	}, [value]); // this code will run when the value of 'value' changes
	return reference.current; // in the end, return the current ref value.
}

export async function loadSavedImage(filePath) {
	// Create a reference with an initial file path and name
	if (!filePath) return '';
	const pathReference = ref(storage, filePath);
	const imgUrl = await getDownloadURL(pathReference);
	return imgUrl;
}

function idQualifyForDiscount(productId, discount, isMainVehicle) {
	return (
		discount.applicableTo?.includes(productId) ||
		discount.applicableTo?.includes(DISCOUNT_APPLICABILTY_KEYWORDS.ALL) ||
		(isMainVehicle &&
			discount.applicableTo?.includes(DISCOUNT_APPLICABILTY_KEYWORDS.MAIN_VEHICLE))
	);
}

export function getApplicableDiscounts(
	checkoutCart,
	oemProfileProps,
	oemVehicles,
	selectedPaymentOption,
	discounts,
	productId,
	isMainVehicle = false,
	isLooseAccessory = false,
) {
	let filteredDiscounts = [];
	let possibleFullPaymentDiscounts = [];
	let possibleKlarnaFullPaymentDiscounts = [];
	if (discounts && discounts.length > 0) {
		filteredDiscounts = discounts.filter((discount) =>
			idQualifyForDiscount(productId, discount, isMainVehicle),
		);
	}
	// add full payment discount if needed
	const checkoutPaymentOverride = checkoutCart?.customLinkConfig?.checkoutPaymentOverride ?? null;
	const paymentConfig = getPaymentConfigOptions(
		oemProfileProps,
		Object.values(oemVehicles || {}),
		checkoutPaymentOverride,
		isPurchasePreorder(checkoutCart),
	);
	if (
		!isLooseAccessory &&
		paymentConfig?.[PAYMENT_CONFIG_KEYS.CHECKOUT_FULL_PAYMENT]?.fullPaymentDiscountPerc
	) {
		possibleFullPaymentDiscounts = [
			{
				amount: paymentConfig?.[PAYMENT_CONFIG_KEYS.CHECKOUT_FULL_PAYMENT]
					?.fullPaymentDiscountPerc,
				type: DISCOUNT_TYPES.PERCENTAGE_DISCOUNT,
				name: `Checkout full payment discount (${toPercentageString(
					paymentConfig?.[PAYMENT_CONFIG_KEYS.CHECKOUT_FULL_PAYMENT]
						?.fullPaymentDiscountPerc,
				)})`,
				dateApplied: Date.now(),
				category: DISCOUNT_CATEGORIES.FULL_PAYMENT_DISCOUNT,
				campaignId: DISCOUNT_CATEGORIES.FULL_PAYMENT_DISCOUNT,
				reid: null,
				applicableTo: [DISCOUNT_APPLICABILTY_KEYWORDS.ALL],
			},
		];
	}
	if (
		!isLooseAccessory &&
		paymentConfig?.[PAYMENT_CONFIG_KEYS.KLARNA_CHECKOUT_FULL_PAYMENT]?.fullPaymentDiscountPerc
	) {
		possibleKlarnaFullPaymentDiscounts = [
			{
				amount: paymentConfig?.[PAYMENT_CONFIG_KEYS.KLARNA_CHECKOUT_FULL_PAYMENT]
					?.fullPaymentDiscountPerc,
				type: DISCOUNT_TYPES.PERCENTAGE_DISCOUNT,
				name: `Checkout full payment discount (${toPercentageString(
					paymentConfig?.[PAYMENT_CONFIG_KEYS.KLARNA_CHECKOUT_FULL_PAYMENT]
						?.fullPaymentDiscountPerc,
				)})`,
				dateApplied: Date.now(),
				category: DISCOUNT_CATEGORIES.FULL_PAYMENT_DISCOUNT,
				campaignId: DISCOUNT_CATEGORIES.FULL_PAYMENT_DISCOUNT,
				reid: null,
				applicableTo: [DISCOUNT_APPLICABILTY_KEYWORDS.ALL],
			},
		];
	}
	if (
		!isLooseAccessory &&
		(selectedPaymentOption === PAYMENT_CHOICES.KLARNA_CHECKOUT_FULL_PAYMENT ||
			selectedPaymentOption === PAYMENT_CHOICES.CHECKOUT_FULL_PAYMENT)
	) {
		const configKey =
			selectedPaymentOption === PAYMENT_CHOICES.CHECKOUT_FULL_PAYMENT
				? PAYMENT_CONFIG_KEYS.CHECKOUT_FULL_PAYMENT
				: PAYMENT_CONFIG_KEYS.KLARNA_CHECKOUT_FULL_PAYMENT;
		// Create the discounts object
		if (
			paymentConfig?.[configKey]?.fullPaymentDiscountPerc &&
			paymentConfig?.[configKey]?.fullPaymentDiscountPerc > 0
		) {
			filteredDiscounts.push({
				amount: paymentConfig?.[configKey]?.fullPaymentDiscountPerc,
				type: DISCOUNT_TYPES.PERCENTAGE_DISCOUNT,
				name: `Checkout full payment discount (${toPercentageString(
					paymentConfig?.[configKey]?.fullPaymentDiscountPerc,
				)})`,
				dateApplied: Date.now(),
				category: DISCOUNT_CATEGORIES.FULL_PAYMENT_DISCOUNT,
				campaignId: null,
				reid: null,
				applicableTo: [DISCOUNT_APPLICABILTY_KEYWORDS.ALL],
			});
		}
	}
	return {
		discounts: filteredDiscounts,
		possibleFullPaymentDiscounts,
		possibleKlarnaFullPaymentDiscounts,
	};
}

export function getDiscountAmount(discount, productPrice) {
	const { type, amount } = discount;
	if (type === DISCOUNT_TYPES.PERCENTAGE_DISCOUNT) {
		return productPrice * amount;
	}
	if (type === DISCOUNT_TYPES.FIXED_AMOUNT_DISCOUNT) {
		return amount;
	}
	createLog('Invalid-discount-type', `Invalid discount type: ${type}`, 'ERROR', { discount });
	return 0;
}

export function getCartDataForTracking(
	checkoutCart,
	uniqueAccessories,
	cartCosts,
	selectedDeliveryOption,
	selectedPaymentOption,
	applicableDiscounts,
	placedOrderId = null,
	preorderIds = null,
) {
	const contents = [];
	// add delivery or pick up fee
	if (placedOrderId && !preorderIds) {
		contents.push({
			quantity: 1,
			description:
				selectedDeliveryOption === DELIVERY_CHOICES.SHIP ? 'Delivery Fee' : 'Pick Up Fee',
			content_id: selectedDeliveryOption,
			content_name:
				selectedDeliveryOption === DELIVERY_CHOICES.SHIP ? 'Delivery Fee' : 'Pick Up Fee',
			price: cartCosts.totalShippingFees + cartCosts.totalPickUpFees,
		});
	}
	// add discounts
	if (cartCosts && cartCosts.totalDiscount > 0) {
		applicableDiscounts.forEach((discount) => {
			let discountString = discount.name;
			if (discount.type === DISCOUNT_TYPES.PERCENTAGE_DISCOUNT) {
				discountString += ` (${toPercentageString(discount.amount)})`;
			}
			const id = discount.reid || discount.campaignId;
			contents.push({
				quantity: 1,
				description: discountString,
				content_id: id,
				content_name: `DISCOUNT: ${discount.name}`,
				discount_type: discount.type,
				discount_category: discount.category,
				category: 'DISCOUNT',
				price: -cartCosts.appliedDiscounts[id],
			});
		});
	}
	// add vehicles from cart
	checkoutCart.vehicles.forEach((vehicle) => {
		const contentItem = {
			content_id: vehicle.productId,
			price: vehicle.basePrice,
			quantity: 1,
			category: 'VEHICLE',
			content_name: vehicle.name,
			variant_id: vehicle.variantId,
		};
		contents.push(contentItem);
	});
	// add accessories from cart
	if (uniqueAccessories) {
		Object.values(uniqueAccessories).forEach((accessory) => {
			const options = accessory.specs.map((spec) => spec.option).join(', ');
			const contentItem = {
				content_id: accessory.productId,
				price: accessory.basePrice,
				quantity: accessory.count,
				category: 'ACCESSORY',
				content_name: `${accessory.name}${options ? ` - ${options}` : ''}`,
				variant_id: accessory.variant_id,
			};
			contents.push(contentItem);
		});
	}
	const data = {
		contents,
		content_type: 'product',
		currency: 'USD',
		value: cartCosts.totalCartCostsPreTaxesAndFees,
		value_with_taxes_and_fees: cartCosts.totalCartCosts,
		delivery_category: selectedDeliveryOption,
		payment_selection: selectedPaymentOption,
		checkout_cart_id: checkoutCart.id,
		order_id: placedOrderId || 'N/A',
		preorder_ids: preorderIds || 'N/A',
		due_today: cartCosts.totalDueToday,
	};
	return data;
}

// Function that calculates all relevant costs and makes them available globally via the cartCosts context.
export const calculateCartCosts = (
	cartVehicles,
	accessoryCounts,
	uniqueAccessories,
	selectedDeliveryOption,
	selectedPaymentOption,
	oemVehicles,
	selectedRegistrationOption,
	stateData,
	oemProfileProps,
	applicableDiscounts,
	registrationState,
	numberOfVinnedVehicles,
	checkoutCart,
	deliveryDist,
	deliveryState,
	pickUpLocation,
	sellingServicesProvider,
	taxEstimates,
	accessoryTaxEstimates,
) => {
	// NOTE: You can assume all objects passed in are defined and not null
	// loop through vehicles and calulate total cost of vehicles
	let totalPossibleShippingFees = 0;
	let totalPossiblePickUpFees = 0;
	let totalVehiclesCost = 0; // doesn't include bolt ons, taxes, fees, or shipping
	let totalPossibleVehicleOrderFees = 0;
	let totalPossibleRegistrationFees = 0;
	let totalPossibleFinancingDownpayment = 0;
	let totalPossibleFullPaymentDiscount = 0;
	let totalPossibleKlarnaFullPaymentDiscount = 0;
	let totalPossibleVIPReservationFees = 0;
	let totalDiscount = 0;
	let totalTempTagFees = 0;

	const ekhoIsSeller =
		!sellingServicesProvider || sellingServicesProvider?.id === EKHO_SERVICES_PROVIDER_ID;
	const sameStateSale =
		(sellingServicesProvider?.legalAddress?.state || 'DE') === registrationState;
	// temp tags included when a buyer is self registering or when they are registering and Ekho is the seller
	// but not when titling only
	if (
		ekhoIsSeller &&
		(selectedRegistrationOption === TAR_PROCESS_ENUM.AUTOMATED ||
			selectedRegistrationOption === TAR_PROCESS_ENUM.ASSISTED)
	) {
		totalTempTagFees = TEMP_TAG_FEE * numberOfVinnedVehicles;
	}

	const perVehicleCosts = {};
	const checkoutPaymentOverride = checkoutCart?.customLinkConfig?.checkoutPaymentOverride ?? null;
	const paymentConfig = getPaymentConfigOptions(
		oemProfileProps,
		Object.values(oemVehicles),
		checkoutPaymentOverride,
		isPurchasePreorder(checkoutCart),
	);
	const requiredDownPaymentPerc = 0; // getRequiredDownPaymentPerc(paymentConfig);

	const appliedDiscounts = {};
	let combinedVehicleOtherDiscounts = 0;

	// total amount paid before checkout
	let totalPrevPaid = 0;
	const mainVehicleId = cartVehicles.reduce((prev, current) =>
		prev.basePrice > current.basePrice ? prev : current,
	).id;

	let shippingFeesSet = false;
	let pickUpFeesSet = false;
	cartVehicles.forEach((vehicle) => {
		// Find the vehicle in oemVehicles that matches the productId of the cart vehicle
		const oemVehicle = oemVehicles[vehicle.productId];
		let shippingFee = 0;
		let pickUpFee = 0;
		// Get the delivery estimate for the vehicle
		if (selectedDeliveryOption === DELIVERY_CHOICES.SHIP && deliveryDist !== null) {
			shippingFeesSet = true;
			// if shipping fee included in checkout cart, default to this value if already set
			if ('shippingFee' in vehicle && vehicle?.shippingFee != null) {
				shippingFee = vehicle.shippingFee;
			} else {
				const { customDeliveryEstFunc, customFuncData } = getDeliveryEstFunc(
					oemProfileProps._oemKey,
					oemVehicle,
					deliveryState,
					deliveryDist,
				);
				shippingFee = getVehicleDeliveryEstimate(
					oemVehicle,
					deliveryState,
					customDeliveryEstFunc,
					customFuncData,
				);
			}
		} else if (selectedDeliveryOption === DELIVERY_CHOICES.PICK_UP && pickUpLocation) {
			pickUpFeesSet = true;
			if ('pickUpFee' in vehicle && vehicle?.pickUpFee != null) {
				pickUpFee = vehicle.pickUpFee;
			} else {
				pickUpFee = getVehiclePickUpEstimate(
					oemVehicle,
					pickUpLocation,
					null, // custom pickup func null for now
					null, // custom pickup data null for now
				);
			}
		}
		const {
			basePrice,
			basePriceWithSpecs,
			id: purchasedVehicleId,
			accessories,
			boltOnAccessories,
		} = vehicle;

		// Redundancy work for canon
		let orderFee;
		if ('orderFee' in vehicle) {
			orderFee = vehicle.orderFee;
		} else {
			orderFee = 0;
		}
		// Check to see if there is an applicable discount for the vehicle
		const isMainVehicle = mainVehicleId === vehicle.id;

		// Add in the amount paid in previous payments before checkout
		let perVehiclePrevPaymentSum = 0;
		if (vehicle.amountsPaidBeforeCheckout) {
			perVehiclePrevPaymentSum = vehicle.amountsPaidBeforeCheckout.reduce(
				(acc, x) => acc + x.amount,
				0,
			);
			totalPrevPaid += perVehiclePrevPaymentSum;
		}
		let tempTagFee = 0;
		if (
			vehicleNeedsVin(oemVehicle) &&
			ekhoIsSeller &&
			(selectedRegistrationOption === TAR_PROCESS_ENUM.AUTOMATED ||
				selectedRegistrationOption === TAR_PROCESS_ENUM.ASSISTED)
		) {
			tempTagFee = TEMP_TAG_FEE;
		}

		// get all the cost of all the accessories
		let accessoriesCost = 0;
		accessories?.forEach((accessory) => {
			accessoriesCost += accessory.price;
		});
		boltOnAccessories?.forEach((boltOnId) => {
			const accessory = uniqueAccessories.get(boltOnId);
			accessoriesCost += accessory.totalPrice;
		});
		const preDiscountTotalPrice = basePriceWithSpecs
			? basePriceWithSpecs + accessoriesCost
			: basePrice + accessoriesCost;
		const perVehicleDiscounts = [];
		const { discounts, possibleFullPaymentDiscounts, possibleKlarnaFullPaymentDiscounts } =
			getApplicableDiscounts(
				checkoutCart,
				oemProfileProps,
				oemVehicles,
				selectedPaymentOption,
				applicableDiscounts,
				vehicle.productId,
				isMainVehicle,
			);
		let vehicleTotalOtherDiscounts = 0;
		discounts.forEach((discount) => {
			const { baseDiscountAmount, totalDiscountAmount, discountObject } =
				calculateDiscountTotals(
					discount,
					vehicle,
					accessories,
					boltOnAccessories,
					uniqueAccessories,
				);

			vehicleTotalOtherDiscounts += totalDiscountAmount;

			const id = discount.reid || discount.campaignId;
			if (id in appliedDiscounts) {
				appliedDiscounts[id] += baseDiscountAmount;
			} else {
				appliedDiscounts[id] = baseDiscountAmount;
			}

			perVehicleDiscounts.push(discountObject);
		});

		possibleFullPaymentDiscounts.forEach((discount) => {
			const { totalDiscountAmount } = calculateDiscountTotals(
				discount,
				vehicle,
				accessories,
				boltOnAccessories,
				uniqueAccessories,
			);
			totalPossibleFullPaymentDiscount += totalDiscountAmount;
		});

		possibleKlarnaFullPaymentDiscounts.forEach((discount) => {
			const { totalDiscountAmount } = calculateDiscountTotals(
				discount,
				vehicle,
				accessories,
				boltOnAccessories,
				uniqueAccessories,
			);
			totalPossibleKlarnaFullPaymentDiscount += totalDiscountAmount;
		});

		let salesTax = 0;
		if (registrationState && taxEstimates) {
			// Find tax for this vehicle from estimates
			const vehicleTaxes = taxEstimates.lineItems.find(
				(item) => item.lineItemId === vehicle.id,
			);
			if (vehicleTaxes) {
				salesTax = vehicleTaxes.taxes.reduce((acc, tax) => acc + tax.amount, 0);
			} else {
				// Fallback to simple calculation if no tax estimate found
				salesTax =
					preDiscountTotalPrice * stateData[registrationState].taxes.salesTaxPercentage;
			}
		}
		const downPayment = Math.max(
			(preDiscountTotalPrice - vehicleTotalOtherDiscounts) * requiredDownPaymentPerc -
				perVehiclePrevPaymentSum,
			0,
		);
		const totalPrice = preDiscountTotalPrice - vehicleTotalOtherDiscounts;
		if (totalPrice < 0) {
			createLog(
				'Invalid-discount-amount',
				`Discount amount is greater than the total price`,
				'CRITICAL',
				{
					discounts,
					vehicle,
				},
			);
			throw new Error('Discount amount is greater than the total price');
		}

		function getTotalPossibleRegistrationFees() {
			let result = 0;
			if (selectedRegistrationOption === TAR_PROCESS_ENUM.AUTOMATED) {
				const tarProcess = TAR_PROCESS_ENUM.AUTOMATED;
				const stateVariableFee = registrationState
					? stateData[registrationState].taxes.variableFee || 0
					: 0;
				const processFeeOverride =
					oemVehicle?.registrationConfig?.[tarProcess]?.processFeeOverride ?? null;
				const ekhoProcessingFee = calcEkhoProcessingFee(
					tarProcess,
					stateVariableFee,
					sameStateSale ? TAR_AGENT_ENUM.CG : TAR_AGENT_ENUM.VI, // if same-state sale we are using CG
					processFeeOverride,
				);
				result +=
					ekhoProcessingFee +
					stateData[registrationState].fees.estimatedRegistrationFees +
					stateData[registrationState].fees.estimatedTitlingFees;
			} else if (selectedRegistrationOption === TAR_PROCESS_ENUM.AUTOMATED_TITLING) {
				const tarProcess = TAR_PROCESS_ENUM.AUTOMATED_TITLING;
				const stateVariableFee = 0;
				const processFeeOverride =
					oemVehicle?.registrationConfig?.[tarProcess]?.processFeeOverride ?? null;
				const ekhoProcessingFee = calcEkhoProcessingFee(
					tarProcess,
					stateVariableFee,
					TAR_AGENT_ENUM.CG,
					processFeeOverride,
				);
				result +=
					ekhoProcessingFee + stateData[registrationState].fees.estimatedTitlingFees;
			}
			return result;
		}

		totalPossibleRegistrationFees += getTotalPossibleRegistrationFees();

		// Saving vehicle amounts. Storing these so they can be used when we enirch the vehicles objects before creating the order objects
		const thisVehicleCosts = {
			preDiscountTotalPrice,
			perVehicleDiscounts,
			totalPrice,
			salesTax,
			orderFee,
			downPayment,
			shippingFee,
			pickUpFee,
			perVehiclePrevPaymentSum,
			tempTagFee,
			totalPossibleRegistrationFees,
		};
		// If the a preorder purchase is being made, optionally add vip reservation fee
		if (
			isPurchasePreorder(checkoutCart) &&
			paymentConfig[PAYMENT_CONFIG_KEYS.PREORDER_VIP_RESERVATION]
		) {
			thisVehicleCosts.vipReservationFee = vehicle.vipOrderFee || 0;
			totalPossibleVIPReservationFees += thisVehicleCosts.vipReservationFee;
		}
		perVehicleCosts[purchasedVehicleId] = thisVehicleCosts;

		// Adjusting totals
		// bolt-ons
		if (basePriceWithSpecs) {
			totalVehiclesCost += basePriceWithSpecs;
		}
		// canon
		else {
			totalVehiclesCost += basePrice;
		}
		totalPossibleVehicleOrderFees += orderFee;
		totalPossibleShippingFees += shippingFee;
		totalPossiblePickUpFees += pickUpFee;
		totalDiscount += vehicleTotalOtherDiscounts;
		combinedVehicleOtherDiscounts += vehicleTotalOtherDiscounts;
	});
	// loop through vehicles and calulate total cost of accessories
	// loose accessories (canon)
	const perAccessoryCosts = {};
	let totalLooseItemCosts = 0;
	let totalLooseItemDiscounts = 0;
	const { looseAccessories } = checkoutCart;
	looseAccessories?.forEach((item) => {
		const { discounts } = getApplicableDiscounts(
			checkoutCart,
			oemProfileProps,
			oemVehicles,
			selectedPaymentOption,
			applicableDiscounts,
			item.productId,
			false,
			true,
		);
		let singleItemDiscountSum = 0;
		discounts?.forEach((discount) => {
			// const appliedDiscount = discount.amount * value;
			const discountAmount = getDiscountAmount(discount, item.price);
			singleItemDiscountSum += discountAmount;
			const id = discount.reid || discount.campaignId;
			if (id in appliedDiscounts) {
				appliedDiscounts[id] += discountAmount;
			} else {
				appliedDiscounts[id] = discountAmount;
			}
		});
		if (singleItemDiscountSum > item.price) {
			createLog(
				'Invalid-discount-amount',
				`Discount amount is greater than the total price`,
				'CRITICAL',
				{
					discounts,
					item,
				},
			);
			throw new Error('Discount amount is greater than the total price');
		}

		totalLooseItemCosts += item.price;

		totalLooseItemDiscounts += singleItemDiscountSum;
		// Agnostic to the count!
		if ('price' in item) {
			perAccessoryCosts[item.id] = {
				preDiscountTotalPrice: item.price,
				totalPrice: item.price - singleItemDiscountSum,
			};
		} else {
			perAccessoryCosts[item.id] = {
				preDiscountTotalPrice: item.totalPrice,
				totalPrice: item.totalPrice - singleItemDiscountSum,
			};
		}
	});
	// vehicle accessories
	let totalAccessoryCosts = 0;
	let totalBoltOnAccessoryCosts = 0;
	accessoryCounts.forEach((value, key) => {
		const accessory = uniqueAccessories.get(key);
		// don't add bolt on accessories into the due today piece
		const { discounts } = getApplicableDiscounts(
			checkoutCart,
			oemProfileProps,
			oemVehicles,
			selectedPaymentOption,
			applicableDiscounts,
			accessory.productId,
			false,
			!accessory.boltOnVehicleIds,
		);
		let singleAccessoryDiscountSum = 0;
		discounts.forEach((discount) => {
			const discountAmount =
				'price' in accessory
					? getDiscountAmount(discount, accessory.price)
					: getDiscountAmount(discount, accessory.basePrice);
			singleAccessoryDiscountSum += discountAmount;
			const id = discount.reid || discount.campaignId;
			if (id in appliedDiscounts) {
				appliedDiscounts[id] += discountAmount * value;
			} else {
				appliedDiscounts[id] = discountAmount * value;
			}
		});
		if (
			singleAccessoryDiscountSum > accessory.price ||
			singleAccessoryDiscountSum > accessory.totalPrice
		) {
			createLog(
				'Invalid-discount-amount',
				`Discount amount is greater than the total price`,
				'CRITICAL',
				{
					discounts,
					accessory,
				},
			);
			throw new Error('Discount amount is greater than the total price');
		}
		// Intentionally leaving out discounts!
		if ('price' in accessory) {
			totalAccessoryCosts += accessory.price * value;
		} else if (!('boltOnVehicleIds' in accessory)) {
			totalLooseItemCosts += accessory.totalPrice * value;
			totalLooseItemDiscounts += singleAccessoryDiscountSum * value;
		} else {
			totalBoltOnAccessoryCosts += accessory.totalPrice * value;
		}
		// Agnostic to the count!
		if ('price' in accessory) {
			perAccessoryCosts[key] = {
				preDiscountTotalPrice: accessory.price,
				totalPrice: accessory.price - singleAccessoryDiscountSum,
			};
		} else {
			perAccessoryCosts[key] = {
				preDiscountTotalPrice: accessory.totalPrice,
				totalPrice: accessory.totalPrice - singleAccessoryDiscountSum,
			};
		}
	});
	// Add in discounts to loose accessories
	totalDiscount += totalLooseItemDiscounts;

	// Now we return the correct total based on the selected delivery option and payment option
	let totalTaxes = 0;
	let totalLooseAccessoryTaxes = 0;
	const rawSubTotalBeforeLooseItems =
		totalVehiclesCost + totalAccessoryCosts + totalBoltOnAccessoryCosts;
	const rawSubtotal = rawSubTotalBeforeLooseItems + totalLooseItemCosts;
	if (registrationState) {
		totalTaxes = taxEstimates
			? calculateTotalTaxesFromEstimates(taxEstimates)
			: rawSubTotalBeforeLooseItems * stateData[registrationState].taxes.salesTaxPercentage; // Fallback
	}
	totalLooseAccessoryTaxes = accessoryTaxEstimates
		? calculateTotalTaxesFromEstimates(accessoryTaxEstimates)
		: totalLooseItemCosts * stateData[registrationState].taxes.salesTaxPercentage;
	const subtotal = rawSubtotal - totalPrevPaid;
	totalPossibleFinancingDownpayment = Math.max(
		(totalVehiclesCost +
			totalAccessoryCosts +
			totalBoltOnAccessoryCosts -
			combinedVehicleOtherDiscounts) *
			requiredDownPaymentPerc -
			totalPrevPaid,
		0,
	);

	let totalCartCost = 0;
	let totalDueToday = 0;
	let totalShippingFees = 0;
	let totalPickUpFees = 0;
	let totalRegistrationFees = 0;
	let totalVehicleOrderFees = 0;
	let totalFinancingDownpayment = 0;

	// FIRST: Switch case for deliver option
	switch (selectedDeliveryOption) {
		case DELIVERY_CHOICES.SHIP:
			totalShippingFees = totalPossibleShippingFees;
			totalPickUpFees = 0;
			break;
		case DELIVERY_CHOICES.PICK_UP:
			// doesn't cost anything to ship
			totalShippingFees = 0;
			totalPickUpFees = totalPossiblePickUpFees;
			break;
		default:
			break;
	}
	switch (selectedRegistrationOption) {
		case TAR_PROCESS_ENUM.AUTOMATED:
		case TAR_PROCESS_ENUM.AUTOMATED_TITLING:
			totalRegistrationFees = totalPossibleRegistrationFees;
			break;
		case TAR_PROCESS_ENUM.ASSISTED:
		case TAR_PROCESS_ENUM.ASSISTED_TITLING:
		case TAR_PROCESS_ENUM.NONE:
			totalRegistrationFees = 0;
			break;
		default:
			break;
	}

	// SECOND: Switch case for payment option
	switch (selectedPaymentOption) {
		case PAYMENT_CHOICES.LOAN_DOWNPAYMENT:
			totalVehicleOrderFees = 0;
			totalFinancingDownpayment = totalPossibleFinancingDownpayment;
			totalCartCost = Math.max(
				subtotal +
					totalShippingFees +
					totalPickUpFees +
					totalRegistrationFees +
					totalTempTagFees -
					totalDiscount +
					totalTaxes +
					totalLooseAccessoryTaxes,
				0,
			);

			totalDueToday =
				totalFinancingDownpayment +
				totalLooseItemCosts -
				totalLooseItemDiscounts +
				totalLooseAccessoryTaxes;
			break;
		case PAYMENT_CHOICES.CHECKOUT_FULL_PAYMENT:
			totalVehicleOrderFees = 0;
			totalFinancingDownpayment = 0;
			totalCartCost = Math.max(
				subtotal +
					totalShippingFees +
					totalPickUpFees +
					totalRegistrationFees +
					totalTempTagFees -
					totalDiscount +
					totalTaxes +
					totalLooseAccessoryTaxes,
				0,
			);
			totalDueToday = totalCartCost;
			break;
		case PAYMENT_CHOICES.KLARNA_CHECKOUT_FULL_PAYMENT:
			totalVehicleOrderFees = 0;
			totalFinancingDownpayment = 0;
			totalCartCost = Math.max(
				subtotal +
					totalShippingFees +
					totalPickUpFees +
					totalRegistrationFees +
					totalTempTagFees -
					totalDiscount +
					totalTaxes +
					totalLooseAccessoryTaxes,
				0,
			);
			totalDueToday = totalCartCost;
			break;
		case PAYMENT_CHOICES.STANDARD_RESERVATION:
			totalVehicleOrderFees = totalPossibleVehicleOrderFees;
			totalFinancingDownpayment = 0;
			totalCartCost = Math.max(
				subtotal +
					totalShippingFees +
					totalPickUpFees +
					totalRegistrationFees +
					totalTempTagFees -
					totalDiscount +
					totalTaxes +
					totalLooseAccessoryTaxes,
				0,
			);
			totalDueToday =
				totalVehicleOrderFees +
				totalLooseItemCosts -
				totalLooseItemDiscounts +
				totalLooseAccessoryTaxes;
			break;
		case PAYMENT_CHOICES.PREORDER_RESERVATION:
			totalVehicleOrderFees = totalPossibleVehicleOrderFees;
			totalFinancingDownpayment = 0;
			totalCartCost = subtotal;
			totalDueToday = totalVehicleOrderFees;
			break;
		case PAYMENT_CHOICES.PREORDER_VIP_RESERVATION:
			totalVehicleOrderFees = totalPossibleVIPReservationFees;
			totalFinancingDownpayment = 0;
			totalCartCost = subtotal;
			totalDueToday = totalVehicleOrderFees;
			break;
		default:
			break;
	}
	const totalCartCostsPreTaxesFeesAndShipping = subtotal - totalDiscount;
	const totalCartCostsPreTaxesAndFees =
		totalCartCostsPreTaxesFeesAndShipping + totalShippingFees + totalPickUpFees;
	const totalCartCostWithoutFullPaymentDiscount = Math.max(
		subtotal +
			totalShippingFees +
			totalPickUpFees +
			totalRegistrationFees +
			totalTempTagFees +
			totalTaxes +
			totalLooseAccessoryTaxes,
		0,
	);
	return {
		// Total cost of all vehicles, non-bolt ons, and bolt ons in the cart, subtracting already paid amounts
		subtotal,
		// Total cost of everything in the cart. Includes taxes, fees, and shipping
		totalCartCost,
		// What the buyer is paying for now. Changes depending on selected payment option. Includes taxes, fees, and shipping
		totalDueToday,
		// Total cost of shipping
		totalShippingFees,
		// Total cost of pickup
		totalPickUpFees,
		// Total taxes (based just on state sales tax percentage)
		totalTaxes,
		// Total cost of loose accessory taxes
		totalLooseAccessoryTaxes,
		// Total cost of registration($0 if buyer is self registering. Otherwise, we use an estimate
		totalRegistrationFees,
		// Total temp tag fees. Simply the number of DOT vehicles times the cost of a temp tag (if the buyer requested one)
		totalTempTagFees,
		// Total cost of vehicle order fees (only not $0 if reservation is selected)
		totalVehicleOrderFees,
		// What the order fees would be if the buyer selected reservation
		totalPossibleVehicleOrderFees,
		// What the VIP reservation fees would be if the buyer selected preorder VIP reservation
		totalPossibleVIPReservationFees,
		// The down payment required (only not $0 if the buyer selected financing)
		totalFinancingDownpayment,
		// Total cost of all loose accessories BEFORE discounts
		totalLooseItemCosts,
		// Total discounts applied to loose accessories
		totalLooseItemDiscounts,
		// What the down payment would be if the buyer selected financing
		totalPossibleFinancingDownpayment,
		// The total discount applied to the order
		totalDiscount,
		// Object containing discount totals per discount
		appliedDiscounts,
		// What the discount would be if the buyer selected cash
		totalPossibleFullPaymentDiscount,
		// What the discount would be if the buyer selected Klarna
		totalPossibleKlarnaFullPaymentDiscount,
		// The per vehicles costs for each vehicle in the cart. It contains the following fields: preDiscountTotalPrice, totalPrice, salesTax, orderFee, downPayment, discount, shippingFee
		perVehicleCosts,
		// Costs per accessory in the cart. It contains the following fields: preDiscountTotalPrice, totalPrice,
		perAccessoryCosts,
		// The total cost of the cart excluding shipping fees, registration fees, but no taxes. Note: this DOES include discounts!
		totalCartCostsPreTaxesFeesAndShipping,
		// The total cost of the cart including shipping fees, registration fees, but no taxes. Note: this DOES include discounts!
		totalCartCostsPreTaxesAndFees,
		// The total cost of the cart including shipping fees, registration fees, and any taxes. Note: this DOES NOT include all possible full payment discounts!
		totalCartCostWithoutFullPaymentDiscount,
		// The total amount already paid associated with this cart
		totalPrevPaid,
		// Has the shipping address been set and fees accurately calculated?
		shippingFeesSet,
		// Has the pick up location been set and fees accurately calculated?
		pickUpFeesSet,
	};
};

export function calculateMonthlyPayment(loanAmount, term, apr) {
	const realApr = Number(apr) / 100.0;
	const r = Number(realApr) / 12.0;
	const newMonthlyPayment = (loanAmount * r * (1.0 + r) ** term) / ((1.0 + r) ** term - 1.0);
	return newMonthlyPayment;
}

export function extractPhoneNumber(phoneNumber) {
	// Check if input is a string
	if (typeof phoneNumber !== 'string') {
		return '';
	}

	// Remove any non-digit characters
	const digitsOnly = phoneNumber.replace(/\D/g, '');

	// Check if the resulting number has a valid length
	if (digitsOnly.length !== 10) {
		return '';
	}

	return digitsOnly;
}

// Functions used to create the hyperlink warranty text for the services and tempt tags disclosure check
export function createWarrantyDisclosureString(vehicles) {
	// Convert Map values to an array
	const vehiclesArray = Array.from(vehicles.values());

	// Ensure uniqueness based on productId
	const uniqueVehicles = [];
	const productIdsSet = new Set();

	vehiclesArray.forEach((vehicle) => {
		if (!productIdsSet.has(vehicle.productId)) {
			productIdsSet.add(vehicle.productId);
			uniqueVehicles.push(vehicle);
		}
	});

	// Generate individual strings
	const warrantyStrings = uniqueVehicles.map((vehicle) => {
		if (vehicle.warrantyUrl && vehicle.warrantyUrl !== '') {
			return `<a href="${vehicle.warrantyUrl}" target="_blank" rel="noopener noreferrer">the ${vehicle.name}</a>`;
		}
		return `the ${vehicle.name}`;
	});

	// Format the list with commas and 'and'
	let formattedString;
	if (warrantyStrings.length > 1) {
		const last = warrantyStrings.pop();
		formattedString = `${warrantyStrings.join(', ')}, and ${last}`;
	} else {
		// eslint-disable-next-line prefer-destructuring
		formattedString = warrantyStrings[0];
	}
	return formattedString;
}

export function mergeOemAndModelDisclosures(oemProfileProps, oemVehicles, promotion) {
	const allDisclosureEntries = [];
	const { checkoutDisclosures: oemCheckoutDisclosures = [] } = oemProfileProps;
	allDisclosureEntries.push(...oemCheckoutDisclosures);
	// get the values of the oemVehicles object
	Object.values(oemVehicles).forEach((vehicle) => {
		const { checkoutDisclosures = [] } = vehicle;
		allDisclosureEntries.push(...checkoutDisclosures);
	});
	if (promotion) {
		allDisclosureEntries.push({
			name: `'${promotion.name}' Promotion Disclosure`,
			description: promotion.disclaimerText,
		});
	}
	return allDisclosureEntries;
}

export async function checkPaymentIntentCompleted(id) {
	const stripeObj = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
	const paymentIntent = await stripeObj.retrievePaymentIntent(id);
	if (paymentIntent.status === 'succeeded') {
		return true;
	}
	return false;
}

// Function used to create an informative string that describes, based on the accessories bought, which ones will be shipped immediately by the OEM vs which will be bolted onto the vehicle
export function createAccessoriesPurchasedString(oem, uniqueAccessories, accessoryCounts) {
	let numBoltOnAccessories = 0;
	let numNonBoltOnAccessories = 0;
	const listOfBoltOnAccessories = [];
	const listOfNonBoltOnAccessories = [];

	// eslint-disable-next-line no-restricted-syntax
	for (const [key, value] of accessoryCounts.entries()) {
		if (value > 0) {
			const product = uniqueAccessories.get(key);
			if ('boltOnVehicleIds' in product) {
				numBoltOnAccessories += value;
				listOfBoltOnAccessories.push(`${value} ${product.name}`);
			} else {
				numNonBoltOnAccessories += value;
				listOfNonBoltOnAccessories.push(`${value} ${product.name}`);
			}
		}
	}

	let accessoriesPurchasedString = '';
	if (listOfNonBoltOnAccessories.length > 0) {
		const nonBoltOnLabel =
			numNonBoltOnAccessories === 1 ? 'non bolt-on accessory' : 'non bolt-on accessories';
		accessoriesPurchasedString += `Your ${numNonBoltOnAccessories} ${nonBoltOnLabel} (${listOfNonBoltOnAccessories.join(
			', ',
		)}) will be shipped to you immediately by ${oem.name}. `;
	}

	if (listOfBoltOnAccessories.length > 0) {
		const boltOnLabel =
			numBoltOnAccessories === 1 ? 'bolt-on accessory' : 'bolt-on accessories';
		const vehicleString =
			numBoltOnAccessories === 1 ? 'its assigned vehicle' : 'their assigned vehicle(s)';
		accessoriesPurchasedString += `Your ${numBoltOnAccessories} ${boltOnLabel} (${listOfBoltOnAccessories.join(
			', ',
		)}) will be installed on ${vehicleString} by ${oem.name}.`;
	}

	return accessoriesPurchasedString;
}

export function getVehicleNameString(vehicles, currentVehicle, activeVehicleIndex) {
	let vehicleName = 'Vehicle';
	let vehicleNameSuffix = '';
	// array of indexes of where this vehicle type appears
	const vehicleIndexes = {};
	let numVehicles = 0;
	vehicles.forEach((veh, index) => {
		if (veh.productId === currentVehicle.productId) {
			numVehicles += 1;
			vehicleIndexes[index] = numVehicles;
		}
	});

	if (currentVehicle !== null) {
		vehicleName = currentVehicle.name;
		// description = activeVehicleItem.description;
		if (numVehicles > 1) {
			vehicleNameSuffix = ` (${vehicleIndexes[activeVehicleIndex]} of ${numVehicles})`;
		}
	}
	return { vehicleName, vehicleNameSuffix };
}

export function createPurchaseTitleStrings(
	checkoutCart,
	vehicles,
	currentOemVehicle,
	currentCartVehicle,
	activeVehicleIndex,
	vehicleCheckoutConfig,
	selectedState,
	selectedPaymentOption,
	mostCompetitiveFinancingProgram,
) {
	let deliveryTimeStr = 'Loading...';
	let bestMonthlyPayment = null;
	let bestMonthlyPaymentDescription = '';
	// Use a static string for delivery expectations on preorders
	const paymentChoice = PAYMENT_CHOICE_TO_SELECTION_MAP[selectedPaymentOption];
	if (
		isPurchasePreorder(checkoutCart) &&
		(checkoutCart?.preorderProdStart || currentOemVehicle?.preorderProdStart)
	) {
		if (
			!selectedState &&
			!checkoutCart.preorderProdStart &&
			vehicleHasPreorderProdStartOverride(currentOemVehicle)
		) {
			deliveryTimeStr = 'Pending state selection';
		} else {
			deliveryTimeStr =
				checkoutCart.preorderProdStart ??
				getStateBasedPreorderProdStart(currentOemVehicle, selectedState) ??
				currentOemVehicle?.preorderProdStart;
		}
	}
	// If delivery date is set and it's an object with a valid payment selection, use this for the purchase string
	else if (
		currentCartVehicle &&
		'estimatedDeliveryDate' in currentCartVehicle &&
		typeof currentCartVehicle.estimatedDeliveryDate === 'object' &&
		paymentChoice in currentCartVehicle.estimatedDeliveryDate
	) {
		const { estimatedDeliveryDate } = currentCartVehicle;
		const deliveryDate = estimatedDeliveryDate[paymentChoice];
		deliveryTimeStr = createEstimatedDeliveryString(null, deliveryDate);
	}
	// If delivery date is set and it's a number, use this for the purchase string
	else if (
		currentCartVehicle &&
		'estimatedDeliveryDate' in currentCartVehicle &&
		typeof currentCartVehicle.estimatedDeliveryDate === 'number'
	) {
		const { estimatedDeliveryDate } = currentCartVehicle;
		deliveryTimeStr = createEstimatedDeliveryString(null, estimatedDeliveryDate);
	}
	// If the production time, delivery time is set in the vehicle, use these values
	else if (
		currentCartVehicle &&
		'estimatedProductionTime' in currentCartVehicle &&
		'estimatedDeliveryTime' in currentCartVehicle
	) {
		const { estimatedProductionTime = 0, estimatedDeliveryTime = 0 } = currentCartVehicle;
		// please compute the estimated delivery date (today + estimatedProductionTime + estimatedDeliveryTime). These variables are in number of days.  Produce a strin gin the form '2 January 2022'
		deliveryTimeStr = createEstimatedDeliveryString(
			estimatedProductionTime + estimatedDeliveryTime,
		);
	}
	// Otherwise, use value on variant OR (worst case) value on oemVehicle
	else if (currentOemVehicle) {
		const avgProdTime =
			currentOemVehicle?.variants?.[currentCartVehicle?.variantId]?.averageProductionTime ??
			currentOemVehicle?.averageProductionTime;
		const totalLeadTime = avgProdTime + currentOemVehicle.averageDeliveryTime;
		switch (vehicleCheckoutConfig.deliveryDateFormat) {
			case DELIVERY_DATE_FORMATS.DELIVERY_WITHIN: {
				deliveryTimeStr = `within ${totalLeadTime} days`;
				break;
			}
			case DELIVERY_DATE_FORMATS.EST_DELIVERY_DATE:
			default: {
				deliveryTimeStr = createEstimatedDeliveryString(totalLeadTime);
				break;
			}
		}
	}

	const { vehicleName, vehicleNameSuffix } = getVehicleNameString(
		vehicles,
		currentCartVehicle,
		activeVehicleIndex,
	);

	if (currentCartVehicle !== null && mostCompetitiveFinancingProgram) {
		const { monthlyPayment, description } = generateMonthlyPaymentEstimateObject(
			currentCartVehicle.basePriceWithAccessories,
			mostCompetitiveFinancingProgram,
		);
		bestMonthlyPayment = monthlyPayment;
		bestMonthlyPaymentDescription = description;
	}
	return {
		purchaseTitle: `Your ${vehicleName}`,
		titleSuffix: vehicleNameSuffix,
		deliveryTimeStr,
		bestMonthlyPayment,
		bestMonthlyPaymentDescription,
	};
}

export function getStateBasedPreorderProdStart(currentOemVehicle, state) {
	const { stateData } = currentOemVehicle;
	if (state && currentOemVehicle) {
		if (state in stateData) {
			const { preorderProdStart } = stateData[state];
			if (preorderProdStart) {
				return preorderProdStart;
			}
		}
	}

	return null;
}

export function vehicleHasPreorderProdStartOverride(currentOemVehicle) {
	const { stateData } = currentOemVehicle;
	let override = false;
	if (stateData) {
		// eslint-disable-next-line consistent-return
		Object.keys(stateData).forEach((key) => {
			if (stateData[key].preorderProdStart) {
				override = true;
			}
		});
	}
	return override;
}

export function createStripeStyling() {
	// Get this pre-computed style for use in the stripe component: var(--bs-body-font-family)
	const bodyFontFamily = getComputedStyle(document.body).getPropertyValue(
		'--bs-body-font-family',
	);
	const bodyFontSize = getComputedStyle(document.body).getPropertyValue('--bs-body-font-size');
	const bodyLineHeight = getComputedStyle(document.body).getPropertyValue(
		'--bs-body-line-height',
	);
	const brandColor = getComputedStyle(document.body).getPropertyValue('--brand-color');
	const brandColorLight = getComputedStyle(document.body).getPropertyValue('--brand-color-light');
	const bodyFontWeight = getComputedStyle(document.body).getPropertyValue(
		'--bs-body-font-weight',
	);
	const bodyBorderRadius = getComputedStyle(document.body).getPropertyValue(
		'--bs-border-radius-lg',
	);
	const appearance = {
		theme: 'flat',
		variables: {
			colorBackground: '#f8f9fa',
			primaryColor: brandColor,
			fontSizeBase: bodyFontSize,
			fontFamily: bodyFontFamily,
			fontColor: '#323232',
			fontWeightNormal: '600',
			fontLineHeight: bodyLineHeight,
			borderRadius: bodyBorderRadius,
			colorDanger: '#f35421',
		},
		rules: {
			'.Label': {
				color: '#6C757d',
				fontWeight: '500',
				marginBottom: '0.5rem',
			},
			'.Input': {
				border: '1px solid #f8f9fa',
				boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',
				padding: '0.625rem 1.25rem',
				lineHeight: '1.5',
			},
			'.Input:focus': {
				borderColor: brandColor,
				boxShadow: `inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 8px ${brandColorLight}`,
			},
			'.Input--invalid': {
				border: '1px solid #f35421',
				boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',
			},
			'.Error': {
				color: '#f35421',
				fontSize: '11.375px',
				fontWeight: bodyFontWeight,
			},
		},
	};
	return appearance;
}

export function createEstimatedDeliveryString(totalLeadTimeInDays, deliveryDate = null) {
	if (deliveryDate !== null) {
		return new Date(deliveryDate).toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		});
	}

	const estimatedDeliveryDate = new Date(Date.now() + totalLeadTimeInDays * 24 * 60 * 60 * 1000);
	return estimatedDeliveryDate.toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});
}

export function createBestTimeUntilString(numDays) {
	let waitTime = '';
	// averageProductionTime is in days
	// Convert to weeks or months if necessary
	if (numDays >= 84) {
		waitTime = `${Math.round(numDays / 30)} months`;
	} else if (numDays >= 28) {
		waitTime = `${Math.round(numDays / 7)} weeks`;
	} else if (numDays === 0) {
		waitTime = `No wait time!`;
	} else {
		// account for 1 day vs multiple days
		waitTime = `${numDays} day${numDays > 1 ? 's' : ''}`;
	}
	return waitTime;
}

/* PLACING ORDER FUNCTIONS START HERE */

// We only collect one address from the buyer, so we need to determine which address to use
export function getBuyerAddress(
	registrationAddress,
	selectedDeliveryOption,
	shippingAddressFormik,
	isPreorder,
) {
	let buyerAddress = null;

	if (isPreorder) {
		buyerAddress = {
			addressLineOne: registrationAddress.addressLineOne,
			addressLineTwo: registrationAddress.addressLineTwo,
			city: registrationAddress.city,
			state: registrationAddress?.state,
			county: registrationAddress.county || '',
			zip: registrationAddress.zip,
		};
	} else if (selectedDeliveryOption === DELIVERY_CHOICES.SHIP) {
		buyerAddress = {
			addressLineOne: shippingAddressFormik?.values.addressLineOne,
			addressLineTwo: shippingAddressFormik?.values.addressLineTwo,
			city: shippingAddressFormik?.values.city,
			state: shippingAddressFormik?.values.state,
			county: shippingAddressFormik?.values.county || '',
			zip: shippingAddressFormik?.values.zip,
		};
	} else if (selectedDeliveryOption === DELIVERY_CHOICES.PICK_UP) {
		// If pick up, use the registration address

		buyerAddress = {
			addressLineOne: registrationAddress.addressLineOne,
			addressLineTwo: registrationAddress.addressLineTwo,
			city: registrationAddress.city,
			state: registrationAddress?.state,
			county: registrationAddress.county || '',
			zip: registrationAddress.zip,
		};
	}
	return buyerAddress;
}

export function getRegistrationAddress(registrationAddressFormik) {
	const registrationAddress = {
		addressString: registrationAddressFormik.values.addressString,
		addressLineOne: registrationAddressFormik.values.addressLineOne,
		addressLineTwo: registrationAddressFormik.values.addressLineTwo,
		city: registrationAddressFormik.values.city,
		state: registrationAddressFormik.values.state,
		county: registrationAddressFormik.values.county,
		zip: registrationAddressFormik.values.zip,
		lat: registrationAddressFormik.values.lat,
		lng: registrationAddressFormik.values.lng,
	};
	return registrationAddress;
}

export function isRegistrationAddressComplete(registrationAddress) {
	const isValidString = (value) => {
		return typeof value === 'string' && value.trim().length > 0;
	};

	const isValidNumber = (value) => {
		return value !== undefined && typeof value === 'number' && !Number.isNaN(value);
	};

	return (
		registrationAddress &&
		isValidString(registrationAddress.addressString) &&
		isValidString(registrationAddress.addressLineOne) &&
		isValidString(registrationAddress.city) &&
		isValidString(registrationAddress.state) &&
		isValidString(registrationAddress.county) &&
		isValidString(registrationAddress.zip) &&
		isValidNumber(registrationAddress.lat) &&
		isValidNumber(registrationAddress.lng)
	);
}

export function getMailingAddress(
	mailingAddressFormik,
	shippingAddressFormik,
	mailingAddressSameAsShipping,
	selectedDeliveryOption,
) {
	let mailingAddress = null;
	if (mailingAddressSameAsShipping && selectedDeliveryOption === DELIVERY_CHOICES.SHIP) {
		mailingAddress = {
			addressString: shippingAddressFormik?.values.addressString,
			addressLineOne: shippingAddressFormik?.values.addressLineOne,
			addressLineTwo: shippingAddressFormik?.values.addressLineTwo,
			city: shippingAddressFormik?.values.city,
			state: shippingAddressFormik?.values.state,
			county: shippingAddressFormik?.values.county || '',
			zip: shippingAddressFormik?.values.zip,
		};
	} else {
		mailingAddress = {
			addressString: mailingAddressFormik.values.addressString,
			addressLineOne: mailingAddressFormik.values.addressLineOne,
			addressLineTwo: mailingAddressFormik.values.addressLineTwo,
			city: mailingAddressFormik.values.city,
			state: mailingAddressFormik.values.state,
			county: mailingAddressFormik.values.county || '',
			zip: mailingAddressFormik.values.zip,
		};
	}
	return mailingAddress;
}

export function handleError(intent, setErrorString, result = null) {
	createLog(
		'Checkout-payment-error',
		`Payment error in checkout for payment intent with status ${
			intent?.status
		}. Result was ${JSON.stringify(result)}`,
		'ERROR',
		{ placedOrderId: intent?.metadata?.order_id, paymentIntentId: intent?.id },
	);
	if (intent?.status === 'requires_payment_method' || result?.error) {
		const error = intent?.last_payment_error || result.error || { type: 'unknown_error' };
		// can show errors to customers if they are card errors
		if (error.type === 'card_error') {
			// Not clear if we are allowed to share the decline code with the customer
			// Not sharing for the time being
			// if (error.decline_code) {
			//  setErrorString(
			//      `There was an error processing your payment. ${error.message} Your bank provided the following information about the error: ${error.decline_code}`,
			//  );
			// } else {
			setErrorString(`There was an error processing your payment. ${error.message}`);
			// }
		} else if (error.type === 'validation_error') {
			setErrorString(`There was an error processing your payment. ${error.message}`);
		} else {
			setErrorString(
				'There was an error processing your payment. Please check your payment details, shipping address, and billing address for any mistakes. Otherwise, please try again with a different payment method or card, or at another time.',
			);
		}
	} else {
		createLog(
			'Unrecognized-checkout-payment-error',
			`Buyer will receive no information. Payment error in checkout for payment intent with id ${
				intent?.id
			}. Result object: ${JSON.stringify(result)}`,
			'CRITICAL',
			{ placedOrderId: intent?.metadata?.order_id, paymentIntentId: intent?.id },
		);
	}
}

export function applyDiscountCode(code, oem, applicableDiscounts, setApplicableDiscounts) {
	const { discountCampaigns } = oem;
	const alreadyIncluded = applicableDiscounts.some((discount) => discount.campaignId === code);
	if (
		discountCampaigns &&
		code in discountCampaigns &&
		isDiscountLive(discountCampaigns[code]) &&
		!alreadyIncluded &&
		applicableDiscounts.length === 0 // no stacking of discounts allowed
	) {
		const discountToAdd = {
			name: discountCampaigns[code].name,
			type: discountCampaigns[code].type,
			amount: discountCampaigns[code].amount,
			applicableTo: discountCampaigns[code].applicableTo,
			reid: null,
			campaignId: discountCampaigns[code].id,
			category: DISCOUNT_CATEGORIES.DISCOUNT_CAMPAIGN,
		};
		setApplicableDiscounts([...applicableDiscounts, discountToAdd]);
		// Segment event to track when a buyer applies a discount code
		track_buyer_applied_discount(oem, code);
		return true;
	}
	track_discount_failed(oem, code);
	return false;
}

/* END OF PLACING ORDER FUNCTIONS */
export function createStringFromAddress(address) {
	let addressString = address.addressLineOne ? address.addressLineOne : '';
	if ('addressLineTwo' in address && address.addressLineTwo) {
		addressString += ` ${address.addressLineTwo}`;
	}
	if ('city' in address && address.city) {
		addressString += `, ${address.city}`;
	}
	if ('state' in address && address.state) {
		addressString += `, ${address.state}`;
	}
	if ('zip' in address && address.zip) {
		addressString += ` ${address.zip}`;
	}
	return addressString;
}

export function createSimpleStringFromAddress(address) {
	// only return the city, state, and zip
	let addressString = '';
	if ('city' in address && address.city) {
		addressString += `${address.city}`;
	}
	if ('state' in address && address.state) {
		addressString += `, ${address.state}`;
	}
	if ('zip' in address && address.zip) {
		addressString += ` ${address.zip}`;
	}
	return addressString;
}

export function pickUpLocsContainOemLocs(oemPickUpLocations) {
	// see if any of the pick up locations have type === 'OEM'
	return oemPickUpLocations.some((loc) => loc.type === PICK_UP_LOCATION_TYPE_ENUM.OEM);
}

export function generatePickUpLocName(pickUpLoc) {
	if (pickUpLoc.type === PICK_UP_LOCATION_TYPE_ENUM.EKHO) {
		let addressString = '';
		if (pickUpLoc.city) {
			addressString += `${pickUpLoc.city}`;
		}
		if (pickUpLoc.state) {
			addressString += `, ${pickUpLoc.state}`;
		}
		return `${addressString} Pick Up Location`;
	}
	return `${pickUpLoc.name}`;
}

export function getMilesBetweenCoords(coord1, coord2) {
	const toRadians = (angle) => angle * (Math.PI / 180);

	const earthRadiusKm = 6371;

	const dLat = toRadians(coord2.lat - coord1.lat);
	const dLon = toRadians(coord2.lng - coord1.lng);

	const lat1 = toRadians(coord1.lat);
	const lat2 = toRadians(coord2.lat);

	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);

	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

	const distanceKm = earthRadiusKm * c;
	return distanceKm * 0.621371; // Convert km to miles
}

export function createRegistrationSectionDescription(
	vehicles,
	// regOptionsSupported,
) {
	let description = '';
	if (vehicles.length === 1) {
		description =
			'Let us know how you plan to use your vehicle to determine any necessary paperwork.';
	} else {
		description =
			'Let us know how you plan to use your vehicles to determine any necessary paperwork.';
	}

	return description;
}

export function cartContainsThisTarAgent(oemVehicles, thisTarAgent, selectedRegistrationOption) {
	// eslint-disable-next-line no-restricted-syntax
	for (const oemVehicle of Object.values(oemVehicles)) {
		const { registrationConfig } = oemVehicle;
		const oemVehicleRegObject = registrationConfig[selectedRegistrationOption];
		if (!oemVehicleRegObject) {
			return false;
		}
		const { tarAgent } = oemVehicleRegObject;
		if (tarAgent && tarAgent === thisTarAgent) {
			return true;
		}
	}
	return false;
}

export function createRefundPolicyString(selectedPaymentOption, vehicles) {
	if (
		Object.values(vehicles).every((vehicle) => !vehicleNeedsVin(vehicle)) &&
		selectedPaymentOption !== PAYMENT_CHOICES.LOAN_DOWNPAYMENT
	) {
		return 'You acknowledge and agree that, after placing your order below you are ineligible for any refund.';
	}
	const documents = `the Purchase Agreement${
		selectedPaymentOption === PAYMENT_CHOICES.STANDARD_RESERVATION ||
		selectedPaymentOption === PAYMENT_CHOICES.LOAN_DOWNPAYMENT
			? ' or accepting your financing offer'
			: ''
	}`;

	return `You acknowledge and agree that, after placing your order below and signing ${documents}, you are ineligible for any refund. Before then, you can request a refund by emailing support@ekhodealer.com.`;
}

export function findDaysUntilLatestShippingTime(cartVehicles, oemVehicles) {
	// in the array, find the vehicle with the biggest .averageDeliveryTime
	let maxTime = 0;
	// initialize to the first vehicle

	// get an array from all the values in the oemVehicles object
	const oemVehiclesArray = Object.values(oemVehicles);
	cartVehicles.forEach((vehicle) => {
		if ('estimatedDeliveryTime' in vehicle) {
			const deliveryTime = vehicle.estimatedDeliveryTime || 0;
			if (deliveryTime > maxTime) {
				maxTime = deliveryTime;
			}
		} else {
			const matchingOemVehicle = oemVehiclesArray.find(
				(oemVehicle) => oemVehicle.productId === vehicle.productId,
			);
			if (matchingOemVehicle.averageDeliveryTime > maxTime) {
				maxTime = matchingOemVehicle.averageDeliveryTime;
			}
		}
	});
	return maxTime;
}

export function toPercentageString(num) {
	if (!num) return '0%';
	const percentage = num * 100;
	// Check if the percentage is a whole number
	if (percentage % 1 === 0) {
		return `${percentage}%`; // Return without decimals
	}
	return `${percentage.toFixed(2)}%`; // Keep two decimal places
}

export async function triggerOrderDetailsAbandonedCartUpdate(
	abandonedCartId,
	accountDetailsFormik,
	shippingAddressFormik,
	registrationAddressFormik,
	selectedDeliveryOption,
	selectedRegistrationOption,
	selectedPaymentOption,
	selectedPickUpLocation,
	regAddressSameAsShipping,
	abandonedCartData,
	setAbandonedCartData,
) {
	const { fullName, emailAddress, phoneNumber } = accountDetailsFormik.values;
	const {
		addressString: shippingAddressString,
		addressLineOne: shippingAddressLineOne,
		addressLineTwo: shippingAddressLineTwo,
		city: shippingAddressCity,
		state: shippingAddressState,
		county: shippingAddressCounty,
		zip: shippingAddressZip,
	} = shippingAddressFormik.values;
	const {
		addressString: registrationAddressString,
		addressLineOne: registrationAddressLineOne,
		addressLineTwo: registrationAddressLineTwo,
		city: registrationAddressCity,
		state: registrationAddressState,
		county: registrationAddressCounty,
		zip: registrationAddressZip,
	} = registrationAddressFormik.values;
	const abandonedCartUpdate = {
		id: abandonedCartId,
		customLinkConfig: {
			prefilledInfo: {
				shippingAddress: {},
				registrationAddress: {},
			},
		},
	};
	if (registrationAddressState) {
		abandonedCartUpdate.registrationState = registrationAddressState;
	}
	if (fullName) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.buyerFullName = fullName;
	}
	if (emailAddress) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.buyerEmail = emailAddress;
	}
	if (phoneNumber) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.buyerPhone = phoneNumber;
	}
	if (selectedPaymentOption) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.selectedPaymentOption =
			selectedPaymentOption;
	}
	if (selectedDeliveryOption) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.selectedDeliveryOption =
			selectedDeliveryOption;
	}
	if (selectedRegistrationOption) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.selectedRegistrationOption =
			selectedRegistrationOption;
	}
	if (typeof regAddressSameAsShipping === 'boolean') {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.regAddressSameAsShipping =
			regAddressSameAsShipping;
	}
	if (shippingAddressString) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.shippingAddress.addressString =
			shippingAddressString;
	}
	if (shippingAddressLineOne) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.shippingAddress.addressLineOne =
			shippingAddressLineOne;
	}
	if (shippingAddressLineTwo) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.shippingAddress.addressLineTwo =
			shippingAddressLineTwo;
	}
	if (shippingAddressCity) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.shippingAddress.city =
			shippingAddressCity;
	}
	if (shippingAddressState) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.shippingAddress.state =
			shippingAddressState;
	}
	if (shippingAddressCounty) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.shippingAddress.county =
			shippingAddressCounty;
	}
	if (shippingAddressZip) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.shippingAddress.zip = shippingAddressZip;
	}
	if (registrationAddressString) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.registrationAddress.addressString =
			registrationAddressString;
	}
	if (registrationAddressLineOne) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.registrationAddress.addressLineOne =
			registrationAddressLineOne;
	}
	if (registrationAddressLineTwo) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.registrationAddress.addressLineTwo =
			registrationAddressLineTwo;
	}
	if (registrationAddressCity) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.registrationAddress.city =
			registrationAddressCity;
	}
	if (registrationAddressState) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.registrationAddress.state =
			registrationAddressState;
	}
	if (registrationAddressCounty) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.registrationAddress.county =
			registrationAddressCounty;
	}
	if (registrationAddressZip) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.registrationAddress.zip =
			registrationAddressZip;
	}
	if (selectedPickUpLocation?.id) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.selectedPickUpLocationId =
			selectedPickUpLocation.id;
	}
	// If the abandonedCartUpdate is the same as the abandonedCartData, don't update the abandoned cart
	if (_.isEqual(abandonedCartUpdate, abandonedCartData)) {
		return { message: 'No changes detected. Not updating abandoned cart.' };
		// eslint-disable-next-line no-else-return
	}
	setAbandonedCartData(abandonedCartUpdate);
	const updateRes = await updateAbandonedCartServerCall(abandonedCartId, abandonedCartUpdate);
	return updateRes;
}

export async function triggerCreditAppAbandonedCartUpdate(
	abandonedCartId,
	applicantDetailsFormik,
	applicantEmploymentFormik,
	abandonedCartData,
	setAbandonedCartData,
) {
	const {
		addressString,
		addressLineOne,
		addressLineTwo,
		city,
		county,
		state,
		zip,
		maritalStatus,
		housingOwnership,
		homeMoveInDate,
		monthlyAmt,
		noteToLender,
		homeAddressSameAsIdAddress,
		loanTerm,
		downpaymentAmt,
	} = applicantDetailsFormik.values;

	const {
		employerName,
		jobStartDate,
		monthlyGrossIncome,
		occupation,
		addressString: employerAddressString,
		addressLineOne: employerAddressLineOne,
		addressLineTwo: employerAddressLineTwo,
		city: employerCity,
		county: employerCounty,
		state: employerState,
		zip: employerZip,
		employmentStatus,
		employerPhoneNumber,
		otherIncomes,
	} = applicantEmploymentFormik.values;

	const abandonedCartUpdate = {
		id: abandonedCartId,
		customLinkConfig: {
			prefilledInfo: {
				creditApplication: {
					applicantDetails: {},
					employmentDetails: {},
				},
			},
		},
	};

	/* Applicant Details */
	if (addressString) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.addressString =
			addressString;
	}
	if (addressLineOne) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.addressLineOne =
			addressLineOne;
	}
	if (addressLineTwo) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.addressLineTwo =
			addressLineTwo;
	}
	if (city) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.city =
			city;
	}
	if (county) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.county =
			county;
	}
	if (state) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.state =
			state;
	}
	if (zip) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.zip =
			zip;
	}
	if (maritalStatus) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.maritalStatus =
			maritalStatus;
	}
	if (housingOwnership) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.housingOwnership =
			housingOwnership;
	}
	if (homeMoveInDate) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.homeMoveInDate =
			homeMoveInDate;
	}
	if (monthlyAmt) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.monthlyAmt =
			monthlyAmt;
	}
	if (noteToLender) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.noteToLender =
			noteToLender;
	}
	if (homeAddressSameAsIdAddress) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.homeAddressSameAsIdAddress =
			homeAddressSameAsIdAddress;
	}
	if ('loanTerm' in applicantDetailsFormik.values) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.loanTerm =
			loanTerm;
	}
	if ('downpaymentAmt' in applicantDetailsFormik.values) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.applicantDetails.downpaymentAmt =
			downpaymentAmt;
	}

	/* Applicant Emnployment Details */
	if (employerName) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.employerName =
			employerName;
	}
	if (jobStartDate) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.jobStartDate =
			jobStartDate;
	}
	if (monthlyGrossIncome) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.monthlyGrossIncome =
			monthlyGrossIncome;
	}
	if (occupation) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.occupation =
			occupation;
	}
	if (employerAddressString) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.addressString =
			employerAddressString;
	}
	if (employerAddressLineOne) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.addressLineOne =
			employerAddressLineOne;
	}
	if (employerAddressLineTwo) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.addressLineTwo =
			employerAddressLineTwo;
	}
	if (employerCity) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.city =
			employerCity;
	}
	if (employerCounty) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.county =
			employerCounty;
	}
	if (employerState) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.state =
			employerState;
	}
	if (employerZip) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.zip =
			employerZip;
	}
	if (employmentStatus) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.employmentStatus =
			employmentStatus;
	}
	if (employerPhoneNumber) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.employerPhoneNumber =
			employerPhoneNumber;
	}
	if (otherIncomes) {
		abandonedCartUpdate.customLinkConfig.prefilledInfo.creditApplication.employmentDetails.otherIncomes =
			otherIncomes;
	}

	// If the abandonedCartUpdate is the same as the abandonedCartData, don't update the abandoned cart
	if (_.isEqual(abandonedCartUpdate, abandonedCartData)) {
		return { message: 'No changes detected. Not updating abandoned cart.' };
		// eslint-disable-next-line no-else-return
	}
	setAbandonedCartData(abandonedCartUpdate);
	const updateRes = await updateAbandonedCartServerCall(abandonedCartId, abandonedCartUpdate);
	return updateRes;
}

// export async function triggerAbandonedCartCreditApplicationUpdate()

// eslint-disable-next-line consistent-return
function customComparator(objValue, othValue, key) {
	// Ignore 'dateApplied' within 'paymentInfo.discounts' in 'vehicles'
	if (key === 'discounts') {
		const modifiedObjValue = objValue.map((discount) => _.omit(discount, 'dateApplied'));
		const modifiedOthValue = othValue.map((discount) => _.omit(discount, 'dateApplied'));
		return _.isEqual(modifiedObjValue, modifiedOthValue);
	}
	// For other fields, return undefined to let lodash handle the comparison with its default behavior
}

export function getVariantOptionsFromVehicles(vehicles) {
	// variant options
	const variantOptions = {};
	vehicles.forEach((vehicle, index) => {
		variantOptions[`vehicle_${index}`] = { variant: vehicle.variantId };
	});
	return variantOptions;
}

// Perform deep comparison, except for the specified nested fields
export function comparePlacedOrderCreateInputs(objectA, objectB) {
	const objectsAreEqual = _.isEqualWith(
		objectA,
		objectB,
		// eslint-disable-next-line no-unused-vars
		(objValue, othValue, key, object, other, stack) => {
			if (_.isArray(objValue) && key === 'vehicles') {
				// Since vehicles is an array, iterate over its items for comparison
				return (
					objValue.length === othValue.length &&
					objValue.every((item, index) => {
						return _.isEqualWith(item, othValue[index], customComparator);
					})
				);
			}
			return customComparator(objValue, othValue, key);
		},
	);
	return objectsAreEqual;
}

export function createPaymentAccordionBody(
	cartCosts,
	oemProfileProps,
	selectedPaymentOption,
	setFinanceModalOpen,
	preQualModalData,
	setPreQualModalData,
	preQualErrorString,
	sectionAfterPaymentRef,
	paymentConfig,
	checkoutCart,
	currentVehicle,
	paymentChoice = PAYMENT_CHOICES.LOAN_DOWNPAYMENT,
) {
	let innerFragement = [];
	switch (preQualModalData.status) {
		case PREQUAL_STATUSES.NOT_SUPPORTED:
			innerFragement = [
				<AccordionInnerContent
					iconName='AccountBalance'
					titleString='80% of customers choose this option'
					listComponents={[
						checkDeliveryDateForPaymentSelection(currentVehicle, paymentChoice) && (
							<li>
								Estimated delivery:{' '}
								{fetchDynamicDeliveryDate(currentVehicle, paymentChoice)}
							</li>
						),
						<li>Get your terms in minutes.</li>,
						<li>Customize your deal from the comfort of your home.</li>,
						<li>Pay over 24-84 months.</li>,
					].filter(Boolean)}
					disclaimerComponent={
						<span className='disclaimer-text'>
							Ekho's financing application will appear after clicking the place order
							button.{' '}
							<a href='#' onClick={() => setFinanceModalOpen(true)}>
								<strong>Visualize monthly payments</strong>
							</a>
						</span>
					}
				/>,
			];
			break;
		case PREQUAL_STATUSES.IN_PROGRESS: // Not doing anything because I want to treat it the same as the NOT_STARTED case
		case PREQUAL_STATUSES.NOT_STARTED:
			innerFragement = [
				<AccordionInnerContent
					iconName='AccountBalance'
					titleString='80% of customers choose this option'
					listComponents={[
						checkDeliveryDateForPaymentSelection(currentVehicle, paymentChoice) && (
							<li>
								Estimated delivery:{' '}
								{fetchDynamicDeliveryDate(currentVehicle, paymentChoice)}
							</li>
						),
						<li>Get your terms in minutes.</li>,
						<li>Customize your deal from the comfort of your home.</li>,
						<li>Pay over 24-84 months.</li>,
						<li>
							Optionally{' '}
							<a
								href='#'
								onClick={() => {
									if (
										preQualModalData.status === PREQUAL_STATUSES.IN_PROGRESS ||
										preQualModalData.status === PREQUAL_STATUSES.APPROVED ||
										preQualModalData.status === PREQUAL_STATUSES.DENIED
									) {
										return;
									}
									const newPreQualData = {
										...preQualModalData,
										modalOpen: true,
										initModalPageName:
											PRE_QUAL_MODAL_PAGE_NAMES.INFO_COLLECT_PAGE, // 'infoCollectPage',
										status: PREQUAL_STATUSES.IN_PROGRESS,
									};
									setPreQualModalData(newPreQualData);
									track_checkout_prequal_modal_opened(
										oemProfileProps,
										cartCosts.totalCartCost,
										isPurchasePreorder(checkoutCart),
									);
								}}>
								<strong>pre-qualify</strong>
							</a>{' '}
							without affecting your credit score.{' '}
						</li>,
					].filter(Boolean)}
					disclaimerComponent={
						<span className='disclaimer-text'>
							Ekho's financing application will appear after clicking the place order
							button.{' '}
							<a href='#' onClick={() => setFinanceModalOpen(true)}>
								<strong>Visualize monthly payments</strong>
							</a>
						</span>
					}
				/>,
			];
			break;
		case PREQUAL_STATUSES.APPROVED:
			innerFragement = [
				<>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<strong style={{ margin: 0 }}>YOU'RE PRE-QUALIFIED!</strong>
						<Icon
							icon='verified'
							color='info'
							styles={{ marginLeft: '0.5rem', width: '1.5rem', height: '1.5rem' }}
						/>
					</div>
					<br />
					Congratulations!{' '}
					<strong>
						We are holding your order slot for <u>40 minutes</u>
					</strong>
					. Submit your financing application in the next step to guarantee your slot!
					<br />
					<br />
					<Button
						color='info'
						className='w-100'
						isOutline
						onClick={() =>
							sectionAfterPaymentRef.current?.scrollIntoView({ behavior: 'smooth' })
						}>
						Continue to financing application
					</Button>
					<br />
					<br />
					<span className='disclaimer-text'>
						This pre-qualification is not an approval or offer of credit, nor a
						guarantee of financing. Continue with your order and complete your credit
						application in the next step.{' '}
						{cartCosts &&
						// Check if there's a down payment
						cartCosts.totalPossibleFinancingDownpayment > 0
							? ' Today’s down payment is refundable until you are approved for and accept financing terms. '
							: ' '}
						<a href='#' onClick={() => setFinanceModalOpen(true)}>
							<strong>Visualize monthly payments</strong>
						</a>
					</span>
				</>,
			];
			break;
		case PREQUAL_STATUSES.DENIED:
			innerFragement = [
				<>
					<strong>Continue with your order to submit a financing application</strong>
					<br />
					<br />
					We don't have enough information to be able to pre-qualify you. Continue with
					your order to submit a financing application in the next step and receive a
					final decision.
					<br />
					<br />
					<Button
						color='info'
						className='w-100'
						isOutline
						onClick={() =>
							sectionAfterPaymentRef.current?.scrollIntoView({ behavior: 'smooth' })
						}>
						Continue with my order
					</Button>
					<br />
					<br />
					<span className='disclaimer-text'>
						<a href='#' onClick={() => setFinanceModalOpen(true)}>
							<strong>Visualize monthly payments</strong>
						</a>
					</span>
				</>,
			];
			break;
		default:
			return null;
	}
	if (preQualErrorString) {
		innerFragement.push(
			<>
				<br />
				<br />
				{/* Add some red text with the prequal error */}
				<strong style={{ color: 'var(--bs-form-invalid-color)' }}>
					{preQualErrorString}
				</strong>
			</>,
		);
	}
	return [
		<React.Fragment
			key={`${PAYMENT_CHOICES.LOAN_DOWNPAYMENT}-payment-accordion-${selectedPaymentOption}-prequal`}>
			{innerFragement}
		</React.Fragment>,
	];
}

export function getVehicleCheckoutConfig(oemVehicle) {
	let checkoutConfig = DEFAULT_CHECKOUT_CONFIG;
	if (oemVehicle?.checkoutConfig) {
		checkoutConfig = {
			...DEFAULT_CHECKOUT_CONFIG,
			...oemVehicle.checkoutConfig,
		};
	}
	return checkoutConfig;
}

export async function createBuyerObjects(
	currBuyerEmail,
	buyerAddress,
	registeredUserProps,
	accountDetails,
	oem,
	setErrorStringAndHideAfterDelay,
	setNewUserCreated,
	setRegisteredUser,
) {
	let buyer;
	if (registeredUserProps === null || currBuyerEmail !== registeredUserProps.email) {
		const buyerFullName = accountDetails.fullName;
		// handle all clean up logic in this function
		// only create a buyer if the buyer exists
		buyer = await createOrGetAccountsForBuyer(
			accountDetails.emailAddress.toLowerCase(),
			// User data
			{
				fullName: buyerFullName,
				address: buyerAddress,
				oemId: oem.id,
				phoneNumber: extractPhoneNumber(accountDetails.phoneNumber),
			},
			// if errors come up, set the error string with informative message
			setErrorStringAndHideAfterDelay,
		);

		if ('error' in buyer) {
			setErrorStringAndHideAfterDelay(buyer.errorString);
		}
		// We use this to know whether the Stripe address element should re-render with the new user data.
		setNewUserCreated(true);
		// Set user context with newly created buyer obj
		setRegisteredUser(buyer);
	} else {
		// user is logged in, so we can just use their info
		buyer = registeredUserProps;
	}
	return buyer;
}

export function extractBuyerObjAddress(
	currBuyerEmail,
	userProps,
	checkoutCart,
	registrationAddress,
	selectedDeliveryOption,
	shippingAddressFormik,
	oemProfileProps,
	billingDetails,
) {
	let buyerAddress;
	if (userProps === null || currBuyerEmail !== userProps.email) {
		buyerAddress =
			isPurchasePreorder(checkoutCart) && !oemProfileProps.preorderCollectShippingAddress
				? {
						addressString: '',
						addressLineOne: '',
						addressLineTowo: '',
						city: '',
						state: '',
						zip: billingDetails?.address?.postal_code.toString() || '',
						county: '',
				  }
				: getBuyerAddress(
						registrationAddress,
						selectedDeliveryOption,
						shippingAddressFormik,
						isPurchasePreorder(checkoutCart),
				  );
	} else {
		buyerAddress = userProps.address;
	}
	return buyerAddress;
}

// helper function to trigger an update to the abandoned cart object in the database
export function updateAbandonedCart(
	abandonedCartIdRef,
	checkoutCart,
	creatingAbandonedCart,
	setCreatingAbandonedCart,
	setAbandonedCartId,
	abandonedCartData,
	setAbandonedCartData,
	formikRefObjs,
	checkoutSection,
	additionalData = {},
	fieldValue = null,
	fieldId = null,
	formik = null,
) {
	if (!trackingEnabled()) {
		return;
	}
	// If we're seding in a field name and value, we want to update the abandoned cart if things have changed
	if (fieldId && formik) {
		const isValid =
			!formik.errors[fieldId] && // the field is valid
			!formik.errors.emailAddress && // the email address is valid
			formik.values.emailAddress?.trim(); // there is an email address

		// First check if we need to create the abandoned cart object
		if (
			!abandonedCartIdRef.current &&
			checkoutCart.linkUseType !== 'SINGLE_USE' &&
			!creatingAbandonedCart &&
			isValid
		) {
			// only create the abandoned cart if the email is valid and the link use type is multi use
			// set creatingAbandonedCart to true, Create the abandoned cart, set the abandonedCartId, and set creatingAbandonedCart to false
			setCreatingAbandonedCart(true);
			createAbandonedCart(
				checkoutCart.id,
				formik.values.emailAddress,
				setAbandonedCartId,
				setCreatingAbandonedCart,
			);
			return;
		}
		const valueIsNotEmpty =
			fieldValue !== '' && fieldValue !== null && fieldValue !== undefined;
		if (!isValid || !valueIsNotEmpty) {
			return;
		}
	}

	// If we make it here we want to update the abandoned cart, but only if the abandoned cart id exists
	if (abandonedCartIdRef.current) {
		if (checkoutSection === CHECKOUT_SECTIONS.ORDER_DETAILS) {
			const {
				accountDetailsFormikRef,
				shippingAddressFormikRef,
				registrationAddressFormikRef,
			} = formikRefObjs;
			const {
				selectedDeliveryOption,
				selectedRegistrationOption,
				selectedPaymentOption,
				selectedPickUpLocation,
				regAddressSameAsShipping,
			} = additionalData;
			triggerOrderDetailsAbandonedCartUpdate(
				abandonedCartIdRef.current,
				accountDetailsFormikRef.current,
				shippingAddressFormikRef.current,
				registrationAddressFormikRef.current,
				selectedDeliveryOption,
				selectedRegistrationOption,
				selectedPaymentOption,
				selectedPickUpLocation,
				regAddressSameAsShipping,
				abandonedCartData,
				setAbandonedCartData,
			);
		}
		if (checkoutSection === CHECKOUT_SECTIONS.CREDIT_APP) {
			const { applicantDetailsFormikRef, applicantEmploymentFormikRef } = formikRefObjs;
			triggerCreditAppAbandonedCartUpdate(
				abandonedCartIdRef.current,
				applicantDetailsFormikRef.current,
				applicantEmploymentFormikRef.current,
				abandonedCartData,
				setAbandonedCartData,
			);
		}
	}
}

// helper function for creating user accounts & associated order
export async function createOrderDBObjs(
	placedOrder,
	creditApplication,
	enrichedVehicles,
	enrichedAccessories,
	buyerAddress,
	currBuyerEmail,
	accessoryCounts,
	cartCosts,
	currentPaymentIntent,
	prevOrderBody,
	savedPlacedOrderId,
	savedPurchasedVehicleIds,
	savedAdditionalDuesIds,
	setSavedPlacedOrderId,
	setSavedPurchasedVehicleIds,
	setSavedAdditionalDuesIds,
	setPrevOrderBody,
	setErrorStringAndHideAfterDelay,
	registeredUserProps,
	accountDetails,
	oem,
	setNewUserCreated,
	setRegisteredUser,
	returnVehicleMap,
	metadata = {},
	enrichedLooseAccessories = null, // never present in financing flow since we force the user to place the order and pay upfront
	orderSection = CHECKOUT_SECTIONS.ORDER_DETAILS,
) {
	let response;
	let buyer;
	try {
		buyer = await createBuyerObjects(
			currBuyerEmail,
			buyerAddress,
			registeredUserProps,
			accountDetails,
			oem,
			setErrorStringAndHideAfterDelay,
			setNewUserCreated,
			setRegisteredUser,
		);
		// update the placedOrder object with buyer objects
		placedOrder.buyerId = buyer.id;
		placedOrder.metadata = metadata;

		const placedOrderReqBody = {
			oemKey: process.env.REACT_APP_OEM_KEY,
			placedOrder,
			vehicles: enrichedVehicles,
			looseAccessories: enrichedLooseAccessories
				? Object.fromEntries(enrichedLooseAccessories)
				: {}, // Convert to object so can pass through API call,
			accessoryCounts: accessoryCounts ? Object.fromEntries(accessoryCounts) : {}, // Convert to object so can pass through API call
			uniqueAccessories: enrichedAccessories ? Object.fromEntries(enrichedAccessories) : {}, // Convert to object so can pass through API call
			paymentIntentId: cartCosts.totalDueToday === 0 ? 'NONE' : currentPaymentIntent.id,
			ADDITIONAL_FIELDS_MAP,
			buyer,
			returnVehicleMap,
			orderSection,
			creditApplication,
		};
		if (
			prevOrderBody === null ||
			savedPlacedOrderId === null ||
			savedPurchasedVehicleIds === null ||
			!comparePlacedOrderCreateInputs(placedOrderReqBody, prevOrderBody)
		) {
			response = await createPlacedOrderObj(placedOrderReqBody);
			setSavedPlacedOrderId(response.placedOrderId);
			setSavedPurchasedVehicleIds(response.purchasedVehicleIds);
			setSavedAdditionalDuesIds(response.additionalDuesIds);
			setPrevOrderBody(placedOrderReqBody);
		} else {
			createLog(
				'Order-creation-skipped',
				'Re-using an existing order created from previous purchase attempt',
				'INFO',
				{
					paymentIntentId: currentPaymentIntent.id,
					placedOrderId: savedPlacedOrderId,
				},
			);
			response = {
				placedOrderId: savedPlacedOrderId,
				purchasedVehicleIds: savedPurchasedVehicleIds,
				additionalDuesIds: savedAdditionalDuesIds,
			};
		}
	} catch (e) {
		setErrorStringAndHideAfterDelay(
			"We're having difficulty processing your order at the moment. Please confirm that your card and billing details are correct. Otherwise, please try again.",
		);
		throw new Error(e);
	}
	const toReturn = {
		placedOrderId: response.placedOrderId,
		purchasedVehicleIds: response.purchasedVehicleIds,
		additionalDuesIds: response.additionalDuesIds,
		buyer,
	};
	if (returnVehicleMap) {
		toReturn.vehicleMap = response.vehicleMap;
	}
	return toReturn;
}

export async function handleSuccess(
	trackingData,
	redirectUrl,
	navigate,
	checkoutCart = null,
	paymentIntentId = null,
) {
	// Confirm payment intent first, before any tracking
	if (paymentIntentId) {
		try {
			await handlePaymentIntentConfirmed(paymentIntentId);
		} catch (e) {
			createLog(
				'Payment-confirmation-error',
				`Failed to confirm payment intent ${paymentIntentId} with error ${e}`,
				'ERROR',
				{
					checkoutCartId: checkoutCart?.id,
					paymentIntentId,
				},
			);
		}
	}
	// Function to handle the redirect after tracking
	function redirect() {
		if (redirectUrl.includes('/PurchaseSuccessPage')) {
			// Use useNavigate for internal routing
			const url = new URL(redirectUrl);
			const pathWithQuery = `${url.pathname}${url.search}`;
			// Use useNavigate for internal routing with query parameters
			navigate(pathWithQuery);
		} else if (isInIframe()) {
			postRedirectMessage(redirectUrl);
		} else {
			window.location.href = redirectUrl;
		}
	}

	if (typeof window.ttq === 'object') {
		// TikTok event tracking
		window.ttq.track('PlaceAnOrder', trackingData);
	}
	if (typeof window.fbq === 'function') {
		window.fbq('track', 'placeorder', trackingData);
	}

	if (typeof window.gtag === 'function') {
		try {
			await new Promise((resolve, reject) => {
				const timeoutId = setTimeout(() => {
					reject(new Error('Google Tag Event timed out after 20 seconds'));
				}, 5000);

				window.gtag('event', 'conversion_event_purchase', {
					...trackingData,
					event_callback() {
						clearTimeout(timeoutId);
						resolve();
					},
				});
			});
			// small delay to ensure all events finish sending (fixes Google Ads Conversion Tracking issue)
			await new Promise((resolve) => {
				setTimeout(resolve, 500);
			});
		} catch (error) {
			createLog(
				'Google-tag-tracking-error',
				`Google Tag Event failed: ${error.message}`,
				'WARNING',
				{
					checkoutCartId: checkoutCart?.id,
				},
			);

			await new Promise((resolve) => {
				setTimeout(resolve, 500);
			});
		}
	}

	// Only redirect after all tracking is complete or has failed
	redirect();
}

export async function createOrderRedirectUrl(oem, placedOrderId, purchasedVehicleIds, buyer, auth) {
	const urlPrefix = getBuyerPortalBaseUrl(oem);
	const url = new URL(`${urlPrefix}/vehicle-details`);
	url.searchParams.append('placedOrderId', placedOrderId);
	url.searchParams.append('purchasedVehicleId', purchasedVehicleIds[0]);
	url.searchParams.append('email', buyer.email);
	// append idToken to search params if the current user is logged in and they are the buyer
	if (auth.currentUser) {
		try {
			const claimsBuyerId = (await auth.currentUser.getIdTokenResult()).claims.buyerId;
			if (auth.currentUser.uid === buyer.id || claimsBuyerId === buyer.id) {
				url.searchParams.append('idToken', await auth.currentUser.getIdToken());
			}
		} catch (e) {
			createLog(
				'Failed-to-get-id-token',
				'Failed to get id token for redirect URL',
				'ERROR',
				{ buyerId: buyer.id, placedOrderId },
			);
		}
	}
	const redirectUrl = url.href;
	return redirectUrl;
}

// Function for sending out appropriate segment events and updating financing action item once a buyer has completed identity verification in financing
export async function handleCreditAppSubmissionTasks(data) {
	// buyer can submit: financing action item = SUBMIT_CREDIT_APP_AND_REPORT_DECISION, segment event = track_credit_application_submitted_by_buyer
	const { canSubmitCreditAppData, currentVehicle } = data;
	const { canSubmitCreditApp } = canSubmitCreditAppData;
	if (canSubmitCreditApp) {
		// update financingActionItem
		await updateFinancingActionItem(
			currentVehicle.id,
			FinancingActionItemOptions.SUBMIT_CREDIT_APP_AND_REPORT_DECISION,
		);
	}
	// buyer can't submit: financing action item = WAIT_FOR_SUBMISSION_WINDOW, segment event = track_credit_application_created_by_buyer
	else {
		const { daysUntilSubmission } = canSubmitCreditAppData;
		// update financingActionItem
		await updateFinancingActionItem(
			currentVehicle.id,
			FinancingActionItemOptions.WAIT_FOR_SUBMISSION_WINDOW,
			daysUntilSubmission,
		);
	}
}

export function calculateVehicleConfigPrice(currentVehicleConfig, vehicleConfig, activeModule) {
	const { basePrice, categories } = vehicleConfig;
	const configKeys = Object.keys(currentVehicleConfig);
	let totalCost = basePrice;
	configKeys.forEach((category) => {
		const configCategory = categories.find((item) => item.categoryId === category);
		if (activeModule === MODULES.CONFIGURATOR) {
			const acc = currentVehicleConfig[category];
			totalCost += acc.price;
		} else if (!configCategory.loose) {
			const acc = currentVehicleConfig[category];
			totalCost += acc.price;
		}
	});
	return totalCost;
}

export function calculateVehicleTotalPrice(checkoutCartVehicle) {
	// If basePriceWithSpecs is already defined, return it
	if (checkoutCartVehicle.basePriceWithSpecs !== undefined) {
		return checkoutCartVehicle.basePriceWithSpecs;
	}

	// Start with the base vehicle price
	let totalPrice = checkoutCartVehicle.basePrice || 0;

	// Add prices of all accessories if they exist
	if (Array.isArray(checkoutCartVehicle.accessories)) {
		totalPrice += checkoutCartVehicle.accessories.reduce((sum, accessory) => {
			return sum + (accessory.price || 0);
		}, 0);
	}
	return totalPrice;
}

export function extractLooseCategories(currentVehicleConfig, vehicleConfig) {
	// First find all loose categories from vehicle config
	const looseCategories = vehicleConfig.categories
		.filter((category) => category.loose === true)
		.map((category) => category.categoryId);

	// Extract loose category configurations from current config
	const looseCategoryConfigs = Object.entries(currentVehicleConfig)
		.filter(([categoryId]) => looseCategories.includes(categoryId))
		// eslint-disable-next-line no-shadow, no-unused-vars
		.map(([_, config]) => config);

	return looseCategoryConfigs;
}

export function checkDeliveryDateForPaymentSelection(currVehicle, paymentSelection = null) {
	const paymentChoice = paymentSelection
		? PAYMENT_CHOICE_TO_SELECTION_MAP[paymentSelection]
		: null;
	if (
		'estimatedDeliveryDate' in currVehicle &&
		typeof currVehicle.estimatedDeliveryDate === 'object' &&
		(paymentChoice === null || paymentChoice in currVehicle.estimatedDeliveryDate)
	) {
		return true;
	}
	return false;
}

// eslint-disable-next-line consistent-return
export function fetchDynamicDeliveryDate(currentCartVehicle, paymentSelection) {
	const paymentChoice = PAYMENT_CHOICE_TO_SELECTION_MAP[paymentSelection];
	if (
		currentCartVehicle &&
		'estimatedDeliveryDate' in currentCartVehicle &&
		typeof currentCartVehicle.estimatedDeliveryDate === 'object' &&
		paymentChoice in currentCartVehicle.estimatedDeliveryDate
	) {
		const { estimatedDeliveryDate } = currentCartVehicle;
		const deliveryDate = estimatedDeliveryDate[paymentChoice];
		return createEstimatedDeliveryString(null, deliveryDate);
	}
}

export async function getAndSetRelevantFinancingPrograms(
	vehiclesArr,
	setRelevantFinancingPrograms,
	oem,
	regState = null,
) {
	if (!vehiclesArr || vehiclesArr.length === 0) {
		return;
	}
	// Take the first vehicle from the array
	const firstVehicle = vehiclesArr[0];
	const { relevantFinancingPrograms: financingPrograms } = await fetchRelevantFinancingPrograms(
		firstVehicle,
		firstVehicle.oemId || oem.id,
		regState,
	);
	setRelevantFinancingPrograms(financingPrograms || {});
}

export async function encryptCreditApplicationData(creditApplication) {
	// Encrypt the applicant credit application data
	const dateFormattedApplicationDetails = dateFormatData(creditApplication.applicantDetails);
	const encryptedApplicantDetails = await encryptData(
		dateFormattedApplicationDetails,
		process.env.REACT_APP_ENCRYPTION_KEY,
	);
	const dateFormattedEmploymentDetails = dateFormatData(creditApplication.employmentDetails);
	const encryptedEmploymentDetails = await encryptData(
		dateFormattedEmploymentDetails,
		process.env.REACT_APP_ENCRYPTION_KEY,
	);
	creditApplication.applicantDetails = encryptedApplicantDetails;
	creditApplication.employmentDetails = encryptedEmploymentDetails;
	return creditApplication;
}

// Add helper function to calculate total taxes from tax estimates
function calculateTotalTaxesFromEstimates(taxEstimates) {
	if (!taxEstimates || !taxEstimates.lineItems) {
		return 0;
	}
	return taxEstimates.lineItems.reduce((total, item) => {
		return total + item.taxes.reduce((acc, tax) => acc + tax.amount, 0);
	}, 0);
}

export function transformVehiclesIntoLineItems(
	vehicles,
	perVehicleDiscounts,
	perVehicleShippingFees,
	registrationSelection,
	perAccessoryCosts,
) {
	let lineItems = [];
	if (vehicles) {
		lineItems = vehicles.map((vehicle) => {
			const boltOnAccessoryPrices =
				vehicle.boltOnAccessories
					?.map((accessoryId) => {
						return perAccessoryCosts[accessoryId].preDiscountTotalPrice; // discounts already accounted for below
					})
					.reduce((acc, price) => acc + price, 0) || 0;
			const vehicleDiscounts = perVehicleDiscounts[vehicle.id];
			const vehicleDiscountSum = vehicleDiscounts.reduce((acc, discount) => {
				return acc + discount.amount;
			}, 0);
			return {
				id: vehicle.id,
				price:
					calculateVehicleTotalPrice(vehicle) +
						boltOnAccessoryPrices -
						vehicleDiscountSum || 0,
				taxClassification:
					registrationSelection === TAR_PROCESS_ENUM.NONE ? 'PHYSICAL_GOOD' : 'VEHICLE',
				shippingFees: perVehicleShippingFees[vehicle.id],
			};
		});
	}
	return lineItems;
}

export function transformLooseAccessoriesIntoLineItems(
	looseAccessories,
	uniqueAccessories,
	perAccessoryCosts,
	isCanon,
) {
	const lineItems = [];
	if (looseAccessories) {
		Array.from(looseAccessories.values()).forEach((looseAccessory) => {
			lineItems.push({
				id: looseAccessory.id,
				price: perAccessoryCosts[looseAccessory.id].totalPrice * looseAccessory.count,
				count: looseAccessory.coun,
				taxClassification: 'PHYSICAL_GOOD',
				shippingFees: looseAccessory.shippingFees || 0,
			});
		});
		// only attempt to include unique accessories if the cart is not canon
	} else if (!isCanon && uniqueAccessories) {
		Array.from(uniqueAccessories.values()).forEach((uniqueAccessory) => {
			// check if unique accessory is bolt on and only include if it is not a bolt on
			if (
				!uniqueAccessory.boltOnVehicleIds ||
				uniqueAccessory.boltOnVehicleIds.length === 0
			) {
				lineItems.push({
					id: uniqueAccessory.id,
					price: perAccessoryCosts[uniqueAccessory.id].totalPrice * uniqueAccessory.count,
					count: uniqueAccessory.count,
					taxClassification: 'PHYSICAL_GOOD',
					shippingFees: uniqueAccessory.shippingFees || 0,
				});
			}
		});
	}
	return lineItems;
}

function calculateDiscountTotals(
	discount,
	vehicle,
	accessories,
	boltOnAccessories,
	uniqueAccessories,
) {
	const baseDiscountAmount = getDiscountAmount(discount, vehicle.basePrice);
	// Calculate accessory discounts
	const totalAccessoryDiscounts =
		accessories?.reduce((total, acc) => {
			if (idQualifyForDiscount(acc.productId, discount)) {
				const accessory = uniqueAccessories.get(acc.productId);
				return total + getDiscountAmount(discount, accessory.price);
			}
			return total;
		}, 0) || 0;
	// Calculate bolt-on discounts
	const totalBoltOnDiscounts =
		boltOnAccessories?.reduce((total, boltOnId) => {
			if (idQualifyForDiscount(boltOnId, discount)) {
				const accessory = uniqueAccessories.get(boltOnId);
				return total + getDiscountAmount(discount, accessory.basePrice);
			}
			return total;
		}, 0) || 0;
	const totalDiscountAmount = baseDiscountAmount + totalAccessoryDiscounts + totalBoltOnDiscounts;
	return {
		baseDiscountAmount,
		totalAccessoryDiscounts,
		totalBoltOnDiscounts,
		totalDiscountAmount,
		discountObject: {
			amount: totalDiscountAmount,
			type: discount.type,
			name: discount.name,
			dateApplied: Date.now(),
			category: discount.category,
			reid: discount.reid,
			campaignId: discount.campaignId,
		},
	};
}
